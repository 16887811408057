import React from "react";
import { MarkerClusterGroup } from "leaflet";

import { Marker } from "react-leaflet";
import { ParadaBalloonIcon } from "../../components/Icons/leaflet-icons/parkingMarker";
import useCustomMap from "../../hooks/useCustomMap";

 const ParadasMarkerContainer = () => {
	const { paradas, handleClickParada } = useCustomMap();
	return (
			<MarkerClusterGroup
				animate={false}
				maxClusterRadius={150}
				spiderfyOnMaxZoom={true} 
			>
				{paradas.map((item) => (
					<Marker 
						eventHandlers={{
							click: (e) => {
								handleClickParada(e);
							},
						}}
						icon={ParadaBalloonIcon}
						position={[item.lat, item.lng]}
					></Marker>
				))}
			</MarkerClusterGroup>
		
	);
};
export default ParadasMarkerContainer