import React, { useContext } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { GlobalDialogContext } from "../../context/GlobalDialogContext";
import { useGlobalDialog } from "../../hooks/useGlobalDialog";
export const GlobalDialog = () => {
	const { isOpen, children, hide } = useGlobalDialog();

	return (
		<Dialog
			style={{ zIndex: 1500 }}
			sx={{ color: "#004017" }}
			open={isOpen}
			onClose={hide}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>{children.val} </DialogContent>
			{/* Dynamic children */}
		</Dialog>
	);
};
