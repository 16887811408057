import { useEffect, useState } from "react";

/**
 * You can send a function to this useFetch
 * recommended use when something is static data and will not be fetched two times
 * and also to have more order having the function in api consumer modules
 * @author PabloTV
 * @param  functionToFetch  aync function without parenthesis (no call)
 * @returns 
 */
export const useFetchFunction = (functionToFetch=()=>{}) => {
	const [res, setRes] = useState({});

	useEffect(() => {
		fetchMyFunction();
		
	}, [functionToFetch]);
	const fetchMyFunction =async () => {
		try {
			
			const data = await functionToFetch();
			console.log({data});
			setRes(data);
		} catch (error) {
			console.log(error);
		}
	};
	return {
		res,
	};
};
