import './appPage.css'

import IphoneX from '../../components/iphonex/iphonex'
import ButtonBadge from '../../components/buttonBadge/buttonBadge'

const AppPage = () => {
    return (
        <div className="background">
            <div className="animate-bottom">
                <IphoneX />
            </div>
            <div className="info animate-bottom">
                <h1>cruzero</h1>
                <h3>
                    <strong>
                        Descarga la app, para tu sistema favorito,<br />
                        encuentranos en PlayStore y en la App Store!
                    </strong>
                </h3>
                <div
                    style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between" }}
                >
                    <ButtonBadge platform="ios" />
                    <ButtonBadge platform="android" />
                </div>
            </div>
        </div>
    )
}

export default AppPage