

import CruzeroCard from "../../components/CruzeroCard";
import MapView from "../../components/MapView/MapView";
import ParadasList from "../../components/SideBar/containers/ParadasList";
import { MainRouteContextProvider } from "../../context/MainRouteContext";
import ParadasMarkerContainer  from "./ParadasMarkerContainer";
const Paradas =()=>{
    return(
        <MainRouteContextProvider>
   
        <MapView LeftSideBarProps={{width:410}}  activeMarkers={{stops:true, poi:true}} sidebarChildren={<CruzeroCard title="Seleccione una Opcion de Parada" color="#004017"/>}  mapContainerChildren={''}>
         
        </MapView>
        </MainRouteContextProvider>);
}

export default Paradas;