import { Button, Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { BusStopIcon } from "../../components/Icons/image-icons/ImageIconsList";
import { MarkersContext } from "../../context/MarkersContext";

export const NewSearchButtonContainer = ({ onClickCallBack }) => {
	const { stopSearcherShow } = useContext(MarkersContext);
	const handlers = {
		click: () => {
			stopSearcherShow.set(true);
			onClickCallBack();
		},
	};
	return (
		<Button
			style={{ margin: "1rem" }}
			color="white"
			variant="contained"
			onClick={handlers.click}
		>
			<Grid container justify="center" alignItems="center">
				<Grid item md={9} xs={9}  className="textColorDarkGreen">
					{" "}
					Nueva Busqueda
				</Grid>
				<Grid item sm={3} md={3} xs={3} >
					<BusStopIcon />
				</Grid>
			</Grid>
		</Button>
	);
};
