import React from 'react'
import { Marker } from 'react-leaflet';
import { DestinationPinIcon, OriginPinIcon } from '../Icons/leaflet-icons/CustomIcons';

export const RouteCalculationMarkers = ({routeCalculationPoints}) => {
    return (
        <div>
            {Object.keys({
					origin: routeCalculationPoints.origin,
					destination: routeCalculationPoints.destination,
				}).map((key, index) => {
					return (
						<Marker
							draggable={true}
							icon={
								key === "origin" ? OriginPinIcon : DestinationPinIcon
							}
							position={[
								routeCalculationPoints[key].val.lat,
								routeCalculationPoints[key].val.long,
							]}
							eventHandlers={{
								dragend: (e) => {

									if (
										//If the lat and long are not null
										routeCalculationPoints.haveValues()
									) {
										const { lat, lng } = e.target.getLatLng();
										routeCalculationPoints[key].set({
											lat: lat,
											long: lng,
										});

										//setRouteCalculationPoints(p);
									}
								},
							}}
						/>
					);
				})}
        </div>
    )
}
