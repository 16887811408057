// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZebraList_list-background-less-opacity__67e9t{\n    \n background-color:  rgba(255, 255, 255, 0.142)!important;\n}", "",{"version":3,"sources":["webpack://src/components/DataContainers/ZebraList.module.css"],"names":[],"mappings":"AAAA;;CAEC,uDAAuD;AACxD","sourcesContent":[".list-background-less-opacity{\n    \n background-color:  rgba(255, 255, 255, 0.142)!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list-background-less-opacity": "ZebraList_list-background-less-opacity__67e9t"
};
export default ___CSS_LOADER_EXPORT___;
