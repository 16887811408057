import { Card, CardHeader, CardContent } from "@material-ui/core/";
import { useTheme } from "@material-ui/styles";

const CruzeroCard = ({
	title,
	children,
	color,
	headerChildren,
	titleColor,
}) => {
	const theme = useTheme();
	return (
		<div style={{ width: "100%" }}>
			<Card
				style={{ margin: "4px", backgroundColor: color ? color : "white" }}
			>
				<CardContent>
					<h4
						style={{
							color:
              titleColor == null
									? theme.palette.primary.main
									: titleColor,
						}}
					>
						{title}
					</h4>
					{headerChildren}
					{children}
				</CardContent>
			</Card>
		</div>
	);
};

export default CruzeroCard;
