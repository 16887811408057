import { createContext } from "react";
import { GlobalDialog } from "../components/GlobalDialog/GlobalDialog";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import useSideBar from "../hooks/useSideBar";
import { useStateNamed } from "../hooks/useStateNamed";

export const DrawerContext = createContext({
	drawers: {
		left: {
			open: false,
			handleDrawerOpen: () => {},
			handleDrawerClose: () => {},
		},
        right: {
			open: false,
			handleDrawerOpen: () => {},
			handleDrawerClose: () => {},
		},
	},
});

export const DrawerContextProvider = ({ children }) => {
	const contextState = {
		drawers: {
			left: useSideBar(),
            right: useSideBar(),
		},
	};
	return (
		<DrawerContext.Provider value={contextState}>
			{children}
		</DrawerContext.Provider>
	);
};
