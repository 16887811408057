import { Grid, List } from "@material-ui/core";
import React from "react";

export const TimelineCustom = ({ children, connector = <></> }) => {
	const flexContainer = {
		display: 'flex',
		flexDirection: 'row',
		padding: 0,
	  };
	return (
		<List  container  direction="row" style={flexContainer} > 
			{children.map((child) => {
				return [
					<Grid >
						<Grid  alignContent='center' alignItems='center' container direction="column">
							{child}
						</Grid>
					</Grid>,
					<Grid >{connector}</Grid>,
				];
			})}
		</List >
	);
};
