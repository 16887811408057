// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageIcon_responsiveIcon__Hjb8T {\n\twidth: 100%;\n\theight: auto;\n}\n", "",{"version":3,"sources":["webpack://src/components/Icons/image-icons/ImageIcon.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;AACb","sourcesContent":[".responsiveIcon {\n\twidth: 100%;\n\theight: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsiveIcon": "ImageIcon_responsiveIcon__Hjb8T"
};
export default ___CSS_LOADER_EXPORT___;
