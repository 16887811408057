import { html } from "d3-fetch";
import { createDivIcon } from "./divIconCreator";

export const BusDivIcon = ({ color = "#FFFF",text="" }) =>
	createDivIcon({
		html: `<svg width="60" height="60" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 70 70">

<g>
 <title>Layer 1</title>
 <path id="svg_1" xxx="0" fill="#fff"/>
 <path fill=${color} transform="translate(-5.10638 -5.95744)" id="svg_2" d="m65.1649,67.39468l-49.83,0a2.86,2.86 0 0 1 -2.85,-2.85l0,-43.93c0,-6.78 4.81,-11.16 12.27,-11.16l31,0c7.46,0 12.28,4.38 12.28,11.16l0,43.93a2.86,2.86 0 0 1 -2.87,2.85zm-40.41,-55.74c-6.22,0 -10.08,3.43 -10.08,9l0,43.89a0.66,0.66 0 0 0 0.66,0.65l49.83,0a0.65,0.65 0 0 0 0.66,-0.65l0,-43.93c0,-5.53 -3.86,-9 -10.08,-9l-30.99,0.04z" class="cls-1"/>
 <path fill=${color} transform="translate(-5.10638 -5.95744)" id="svg_3" d="m61.4949,47.42468a1.09,1.09 0 0 1 -1.1,-1.09l0,-21.91c0,-4.68 -3,-7.58 -7.9,-7.58l-25,0c-4.88,0 -7.91,2.9 -7.91,7.58l0,21.91a1.1,1.1 0 0 1 -2.19,0l0,-21.91c0,-5.85 4.06,-9.78 10.1,-9.78l25,0c6,0 10.1,3.93 10.1,9.78l0,21.91a1.09,1.09 0 0 1 -1.1,1.09z" class="cls-1"/>
 <path fill=${color} transform="translate(-5.10638 -5.95744)" id="svg_4" d="m24.6749,73.62468l-5.11,0a3,3 0 0 1 -3.09,-3l0,-4.38a1.07,1.07 0 0 1 0.33,-0.78a1.06,1.06 0 0 1 0.78,-0.32l9.11,0.15a1.1,1.1 0 0 1 1.08,1.1l0,4.26a3,3 0 0 1 -3.1,2.97zm-6,-6.26l0,3.29a0.84,0.84 0 0 0 0.9,0.77l5.11,0a0.84,0.84 0 0 0 0.9,-0.77l0,-3.18l-6.91,-0.11z" class="cls-1"/>
 <path fill=${color} transform="translate(-5.10638 -5.95744)" id="svg_5" d="m60.5749,73.62468l-5.11,0a3,3 0 0 1 -3.09,-3l0,-4.38a1.1,1.1 0 0 1 0.32,-0.78a1.06,1.06 0 0 1 0.79,-0.32l9.11,0.15a1.1,1.1 0 0 1 1.08,1.1l0,4.26a3,3 0 0 1 -3.1,2.97zm-6,-6.26l0,3.29a0.84,0.84 0 0 0 0.9,0.77l5.11,0a0.84,0.84 0 0 0 0.9,-0.77l0,-3.18l-6.91,-0.11z" class="cls-1"/>
 <path fill=${color} transform="translate(-5.10638 -5.95744)" id="svg_6" d="m49.5149,58.05468l-18.77,0a1.1,1.1 0 0 1 0,-2.2l18.77,0a1.1,1.1 0 0 1 0,2.2z" class="cls-1"/>
 <path fill=${color} transform="translate(-5.10638 -5.95744)" id="svg_7" d="m22.2449,60.52468a3.62,3.62 0 1 1 3.61,-3.61a3.62,3.62 0 0 1 -3.61,3.61zm0,-5a1.42,1.42 0 1 0 1.42,1.42a1.43,1.43 0 0 0 -1.42,-1.45l0,0.03z" class="cls-1"/>
 <path fill=${color} transform="translate(-5.10638 -5.95744)" id="svg_8" d="m57.9149,60.52468a3.62,3.62 0 1 1 3.61,-3.61a3.62,3.62 0 0 1 -3.61,3.61zm0,-5a1.42,1.42 0 1 0 1.42,1.42a1.43,1.43 0 0 0 -1.42,-1.45l0,0.03z" class="cls-1"/>
 <path fill=${color} transform="translate(-5.10638 -5.95744)" id="svg_9" d="m66.6849,47.61468l-53.27,0a1.1,1.1 0 0 1 0,-2.19l53.27,0a1.1,1.1 0 1 1 0,2.19z" class="cls-1"/>
 <path fill=${color} transform="translate(-5.10638 -5.95744)" id="svg_10" d="m6.9149,32.33468a1.09,1.09 0 0 1 -1.09,-1.1l0,-6.87a1.09,1.09 0 0 1 1.09,-1.09l5,0a1.1,1.1 0 0 1 0,2.19l-3.95,0l0,5.77a1.1,1.1 0 0 1 -1.05,1.1z" class="cls-1"/>
 <path fill=${color} transform="translate(-5.10638 -5.95744)" id="svg_11" d="m73.2649,32.33468a1.1,1.1 0 0 1 -1.1,-1.1l0,-5.77l-3.85,0a1.1,1.1 0 1 1 0,-2.19l4.95,0a1.1,1.1 0 0 1 1.1,1.09l0,6.87a1.1,1.1 0 0 1 -1.1,1.1z" class="cls-1"/>
</g>
</svg>
   <label>${text}</label>`,
		size: 12,
	});
export const WalkDivIcon = ({ color = "#FFFF" }) =>
	createDivIcon({
		html: `<svg
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 91.15 92.08"
        width="70"
        height="70"
    >
        <title>Layer 2</title>
        <path
            className="prefix__cls-1"
            fill=${color}
            
            d="M55.41 57.92l-3.63-8.16L54 51a1 1 0 00.49.13 1 1 0 00.89-.5l3.12-5.19a1.05 1.05 0 00-.39-1.44l-6.76-4.1v-8.54a5.18 5.18 0 00-3.55-4.9 7.27 7.27 0 10-9.51 0 5.17 5.17 0 00-3.55 4.9v4.08l-8.07 9.79a1 1 0 00.07 1.39l4.15 4.16a1.06 1.06 0 00.73.3 1 1 0 00.75-.36l2.83-3.25a13.28 13.28 0 002 4.16l-2.39 10.45-6.13 9.18a1.05 1.05 0 00.22 1.39l5.19 4.15a1 1 0 00.65.23h.14a1.05 1.05 0 00.7-.42l8.3-11.42a1 1 0 00.16-.31l1.42-4.72L47.29 62l3.05 13.21a1 1 0 001 .8h6.23a1 1 0 00.79-.37 1.07 1.07 0 00.23-.86l-3.12-16.6a1.09 1.09 0 00-.07-.24zM37.85 21a5.19 5.19 0 1110.38 0 5.1 5.1 0 01-1.16 3.23 3.08 3.08 0 01-.91-2.2h-2.08a5.19 5.19 0 001.39 3.52 5.11 5.11 0 01-2.43.64A5.2 5.2 0 0137.85 21zm-6.28 27.52l-2.7-2.7 5.87-7.12v6.14zM42.1 64.11L34.54 74.5l-3.6-2.87 5.7-8.55a1.09 1.09 0 00.15-.35l2.11-9.13 4.89 4.89zm10.07 9.81l-2.93-12.69a1 1 0 00-.27-.5l-8.82-8.82a11.35 11.35 0 01-3.34-8.07V31.36a3.12 3.12 0 013.12-3.12h6.23a3.12 3.12 0 013.11 3.12v7.3l-1.21-.73-2-5.86-2 .65L46.21 39a1 1 0 00.45.56l9.49 5.7-2.07 3.44-10.23-5.63-4-9-1.9.84 4.15 9.34a1.09 1.09 0 00.46.49l6.72 3.67V49a1.07 1.07 0 00.09.43l4.1 9.23 2.86 15.26z"
        />
    </svg>`,
		size: 12,
	});
