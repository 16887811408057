import { defaultHeaders, genericDestroy, url, genericGet } from "./api.utils";
const TransportAPI = {
	/**
	 *
	 * @returns transport types response
	 */
	getTypes: async () => {
		try {
			const res = await genericGet(`city/transport/types`, {
				city_id: 1,
			});
			return res;
			// console.log(res);
		} catch (error) {
			console.log(error);
		}
	},

	listAllOfType: async (type_id) => {
		try {
			const res = await genericGet(`city/transport/all`, {
				type_id,
				limit: 124,
			});
			return res;
		} catch (error) {
			console.log(error);
		}
	},
	/**
	 *
	 * @param {*} id The transport id
	 * @returns
	 */
	getOne: async (id) => {
		try {
			const res = await genericGet(`city/transport/one/${id}`, {
				intermediate: true,
				deviceTypeId: 1,
			});
			return res;
		} catch (error) {
			console.log(error);
		}
	},
};

export default TransportAPI;
