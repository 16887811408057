import React from "react";

export const BusIconSVG = ({color='#fff'}) => {
	return (
		<svg width="60" height="50" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 75 70">

 <g>
  <title>Layer 1</title>
  <path id="svg_1" xxx="0" fill="#fff"/>
  <path fill={color} transform="translate(-5.10638 -5.95744)" id="svg_2" d="m65.1649,67.39468l-49.83,0a2.86,2.86 0 0 1 -2.85,-2.85l0,-43.93c0,-6.78 4.81,-11.16 12.27,-11.16l31,0c7.46,0 12.28,4.38 12.28,11.16l0,43.93a2.86,2.86 0 0 1 -2.87,2.85zm-40.41,-55.74c-6.22,0 -10.08,3.43 -10.08,9l0,43.89a0.66,0.66 0 0 0 0.66,0.65l49.83,0a0.65,0.65 0 0 0 0.66,-0.65l0,-43.93c0,-5.53 -3.86,-9 -10.08,-9l-30.99,0.04z" class="cls-1"/>
  <path fill={color} transform="translate(-5.10638 -5.95744)" id="svg_3" d="m61.4949,47.42468a1.09,1.09 0 0 1 -1.1,-1.09l0,-21.91c0,-4.68 -3,-7.58 -7.9,-7.58l-25,0c-4.88,0 -7.91,2.9 -7.91,7.58l0,21.91a1.1,1.1 0 0 1 -2.19,0l0,-21.91c0,-5.85 4.06,-9.78 10.1,-9.78l25,0c6,0 10.1,3.93 10.1,9.78l0,21.91a1.09,1.09 0 0 1 -1.1,1.09z" class="cls-1"/>
  <path fill={color} transform="translate(-5.10638 -5.95744)" id="svg_4" d="m24.6749,73.62468l-5.11,0a3,3 0 0 1 -3.09,-3l0,-4.38a1.07,1.07 0 0 1 0.33,-0.78a1.06,1.06 0 0 1 0.78,-0.32l9.11,0.15a1.1,1.1 0 0 1 1.08,1.1l0,4.26a3,3 0 0 1 -3.1,2.97zm-6,-6.26l0,3.29a0.84,0.84 0 0 0 0.9,0.77l5.11,0a0.84,0.84 0 0 0 0.9,-0.77l0,-3.18l-6.91,-0.11z" class="cls-1"/>
  <path fill={color} transform="translate(-5.10638 -5.95744)" id="svg_5" d="m60.5749,73.62468l-5.11,0a3,3 0 0 1 -3.09,-3l0,-4.38a1.1,1.1 0 0 1 0.32,-0.78a1.06,1.06 0 0 1 0.79,-0.32l9.11,0.15a1.1,1.1 0 0 1 1.08,1.1l0,4.26a3,3 0 0 1 -3.1,2.97zm-6,-6.26l0,3.29a0.84,0.84 0 0 0 0.9,0.77l5.11,0a0.84,0.84 0 0 0 0.9,-0.77l0,-3.18l-6.91,-0.11z" class="cls-1"/>
  <path fill={color} transform="translate(-5.10638 -5.95744)" id="svg_6" d="m49.5149,58.05468l-18.77,0a1.1,1.1 0 0 1 0,-2.2l18.77,0a1.1,1.1 0 0 1 0,2.2z" class="cls-1"/>
  <path fill={color} transform="translate(-5.10638 -5.95744)" id="svg_7" d="m22.2449,60.52468a3.62,3.62 0 1 1 3.61,-3.61a3.62,3.62 0 0 1 -3.61,3.61zm0,-5a1.42,1.42 0 1 0 1.42,1.42a1.43,1.43 0 0 0 -1.42,-1.45l0,0.03z" class="cls-1"/>
  <path fill={color} transform="translate(-5.10638 -5.95744)" id="svg_8" d="m57.9149,60.52468a3.62,3.62 0 1 1 3.61,-3.61a3.62,3.62 0 0 1 -3.61,3.61zm0,-5a1.42,1.42 0 1 0 1.42,1.42a1.43,1.43 0 0 0 -1.42,-1.45l0,0.03z" class="cls-1"/>
  <path fill={color} transform="translate(-5.10638 -5.95744)" id="svg_9" d="m66.6849,47.61468l-53.27,0a1.1,1.1 0 0 1 0,-2.19l53.27,0a1.1,1.1 0 1 1 0,2.19z" class="cls-1"/>
  <path fill={color} transform="translate(-5.10638 -5.95744)" id="svg_10" d="m6.9149,32.33468a1.09,1.09 0 0 1 -1.09,-1.1l0,-6.87a1.09,1.09 0 0 1 1.09,-1.09l5,0a1.1,1.1 0 0 1 0,2.19l-3.95,0l0,5.77a1.1,1.1 0 0 1 -1.05,1.1z" class="cls-1"/>
  <path fill={color} transform="translate(-5.10638 -5.95744)" id="svg_11" d="m73.2649,32.33468a1.1,1.1 0 0 1 -1.1,-1.1l0,-5.77l-3.85,0a1.1,1.1 0 1 1 0,-2.19l4.95,0a1.1,1.1 0 0 1 1.1,1.09l0,6.87a1.1,1.1 0 0 1 -1.1,1.1z" class="cls-1"/>
 </g>
</svg>
	);
};
