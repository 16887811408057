import { makeStyles } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import paradasAPI from "../api/paradas.api";
import { PoiAPI } from "../api/poi.api";
import routeAPI from "../api/ruta.api";
import { MainRouteContext } from "../context/MainRouteContext";
import { LinesList } from "../pages/StaticRoutes/containers/LinesList";
import RouteCalculation from "../pages/PersonalizedRoutes/containers/RouteCalculation";
import { TransportSelection } from "../pages/StaticRoutes/containers/TransportSelection";
import { useLoadingWidget } from "./useLoadingSpinner";
import useSideBar from "./useSideBar";
import { useStateNamed } from "./useStateNamed";
import { MarkersContext } from "../context/MarkersContext";
import { NewSearchButtonContainer } from "../pages/Paradas/NewSearchButtonContainer";
import CruzeroCard from "../components/CruzeroCard";
import { isMobile } from "react-device-detect";
import { DrawerContext } from "../context/DrawerContext";
const useStyles = makeStyles((theme) => ({
	fab: {
		position: "absolute",
		bottom: theme.spacing(6),
		right: theme.spacing(4),
		zIndex: 1000,
	},
}));
const useCustomMap = ({ activeMarkers, sidebarChildren }) => {
	// Contextos
	const {
		routesList,
		selectedRoute,
		selectedRouteGeom,
		wayPoints,
		contextFunctions: MainRouteContextFunctions,
	} = useContext(MainRouteContext);

	const {
		poiList,
		stopSearcherShow,
		stopList,
		contextFunctions: MarkersContextFunctions,
	} = useContext(MarkersContext);

	//Drawers / Sidebars
	const { drawers } = useContext(DrawerContext);

	const { loading } = useLoadingWidget();
	const classes = useStyles();
	const [selectedPoi, setSelectedPoi] = useState({});
	const [paradas, setParadas] = useState([]);
	const [pois, setPois] = useState([]);
	const [map, setMap] = useState(null);

	const [sidebarChildrenState, setSidebarChildrenState] = useState(<></>);
	const routeCalculationPoints = {
		origin: wayPoints.origin,
		destination: wayPoints.destination,
		haveValues: () => {
			return (
				routeCalculationPoints.origin.val.lat != 0 &&
				routeCalculationPoints.destination.val.long != 0
			);
		},
		hasOrigin: () => routeCalculationPoints.origin.val.lat != 0,
		hasDestination: () => routeCalculationPoints.destination.val.lat != 0,
	};

	// OnCreate
	useEffect(() => {
		
		if (activeMarkers.poi) {
			listPois();
		}
		if (activeMarkers.stops) {
			listarParadas();
		}
		setTimeout(() => {
			drawers.left.handleDrawerOpen();
		}, 1000);
		return () => {
			poiList.restart();
			stopList.restart();
			drawers.left.handleDrawerClose();
			drawers.right.handleDrawerClose();
		};
	}, []);

	//OnUpdate selectPoint
	useEffect(() => {
		selectedRoute.restart();
		selectedRouteGeom.restart();

		routesList.restart();
		stopList.restart();
		poiList.restart();
		if (wayPoints.isSelectingOrigin.val) {
			wayPoints.origin.set({ lat: 0, long: 0 });
			drawers.left.handleDrawerClose();
		}
		if (wayPoints.isSelectingDestination.val) {
			wayPoints.destination.set({ lat: 0, long: 0 });
			drawers.left.handleDrawerClose();
		}
	}, [wayPoints.isSelectingOrigin.val, wayPoints.isSelectingDestination.val]);
	//OnUpdate Children
	useEffect(() => {
		MainRouteContextFunctions.restart();
		MarkersContextFunctions.restart();
		stopSearcherShow.set(false);
		setSidebarChildrenState(sidebarChildren);
	}, [sidebarChildren]);

	const handlers = {
		clickSearchStops: async (lat, lon) => {
			loading.begin();
			const foundStopsRes = await paradasAPI.getNearLocation({ lat, lon });
			loading.stop();

			stopList.set(foundStopsRes.data);
		},
		clickBusStopMarker: async (e) => {
			try {
				//	const response = await paradasAPI.getNearRoutes(e.latlng);
				//	console.log(response.data[0][0]);
			} catch (error) {
				console.log(error);
			}
		},
		clickButtonNewSearch: () => {
			MarkersContextFunctions.restart();
			MainRouteContextFunctions.restart();
			drawers.left.handleDrawerClose();
			setSidebarChildrenState(
				<>
					<CruzeroCard
						title="Seleccione una Opcion de Parada"
						color="#004017"
					/>
				</>
			);
		},
		clickNearRoutes: async (item) => {
			//	console.log({ item });
			loading.begin();
			const resStops = await paradasAPI.getRoutesNearStops({
				city_id: 2,
				stop_id: item.id,
			});
			loading.stop();

			stopSearcherShow.set(false);
			const component = (
				<>
					<NewSearchButtonContainer
						onClickCallBack={handlers.clickButtonNewSearch}
					/>
					<TransportSelection initialIndex={1} routeList={resStops.data} />
				</>
			);
			setSidebarChildrenState(() => (
				<NewSearchButtonContainer
					onClickCallBack={handlers.clickButtonNewSearch}
				/>
			));
			setSidebarChildrenState(() => component);
			drawers.left.handleDrawerOpen();
		},
		clickGoOnTransport: async (item) => {
			console.log({ item });
			loading.begin();
			//Set Bus Stop as destination
			wayPoints.destination.set({
				lat: item.stop_lat ?? item.poi_lat,
				long: item.stop_lon ?? item.poi_lon,
			});
			wayPoints.isSelectingOrigin.set(true);
			stopList.restart();
			drawers.left.handleDrawerOpen();
			stopSearcherShow.set(false);
			setSidebarChildrenState(() => (
				<>
					{activeMarkers.stops ? (
						<NewSearchButtonContainer
							onClickCallBack={handlers.clickButtonNewSearch}
						/>
					) : null}
					<RouteCalculation />
				</>
			));
			//Note: I am passing the response directly here cuz a state value is not updating when setState, weird thing
			//Note2: The error was cuz of the state of the context was not initialized, in other words the Provider was not in the Parent component
			loading.stop();
		},
		/**
		 *
		 * @param {*} id The clicked poi ID
		 */
		clickPoiMarker: async ({ item }) => {
			console.log({ item });

			if (item.PoiTypeId != null) {
				loading.begin();
				await selectAPoi(item.id);
				drawers.right.handleDrawerOpen();
				loading.stop();
			}
		},
	};
	

	const listarParadas = async () => {
		const json = await paradasAPI.getParadas();
		// console.log(json);

		stopList.set(json);
	};

	const listPois = async () => {
		const json = await PoiAPI.listAll();
		// console.log(json);
		setPois(json);
	};

	const selectAPoi = async (id) => {
		try {
			const poi = await PoiAPI.readDetail(id);
			setSelectedPoi(poi.data);
		} catch (error) {}
	};
	return {
		classes,
		paradas,
		map,
		pois,
		setMap,
		drawers,
		routeCalculationPoints,
		selectedPoi,
		selectAPoi,
		handlers,
		sidebarChildrenState,
	};
};
export default useCustomMap;
