import { Grid } from "@material-ui/core";
import React from "react";
import { BusLineIcon } from "../Icons/image-icons/ImageIconsList";
import { BusIconSVG } from "../Icons/svg-icons/BusIconSVG";

export const LineInformation = ({
	pic_thumbnail_url,
	transport_name,
	onClickPhoto = () => {},
	schedule = { begin: "", end: "", frequency: "" },
}) => {
	return (
		<>
			<Grid container justify="space-between">
				<Grid item xs={4} onClick={onClickPhoto}>
					{" "}
					<img class="img-fluid" src={pic_thumbnail_url} alt="" />
				</Grid>
				<Grid item xs={7}>
					<Grid container>
						{" "}
						<Grid item xs={2}>
							{" "}
							<BusLineIcon />
						</Grid>{" "}
						<Grid item>
							<label htmlFor="" className="textColorDarkGreen text-bold">
								{" "}
								{transport_name}
							</label>
						</Grid>
					</Grid>
					<Grid container>
						<label htmlFor="">Horario : {`${schedule.begin} - ${schedule.end}`}</label>
					</Grid>
				
					<Grid container>
						<label htmlFor=""> Frecuencia : {`${schedule.frequency}`}</label>
					</Grid>
					
				</Grid>
			</Grid>
			<h4> Tarifa:</h4>
			<Grid container  style={{textAlign:'right'}} alignItems="center">
				<Grid item xs={9} htmlFor="">
					{" "}
					Adultos :<label htmlFor="" className="textColorDarkGreen text-bold">
					2 Bs
					</label>  
				</Grid>{" "}
				<br />
				<Grid  item xs={9}>
					{" "}
					Estudiantes:<label htmlFor="" className="textColorDarkGreen text-bold">
					1 Bs
					</label>
				</Grid>
				<Grid item xs={9}>
					{" "}
					Adultos 65+ :<label htmlFor="" className="textColorDarkGreen text-bold">
					1 Bs
					</label> 
				</Grid>
				<Grid item xs={9}>
					{" "}
					Menores 6 años: <label htmlFor="" className="textColorDarkGreen text-bold">
					Gratis
					</label>
				</Grid>
			</Grid>
			<br />
			<Grid container  style={{textAlign:'center'}}>
				<Grid item xs={12}>
					{" "}
					<label htmlFor="" className="textColorDarkGreen text-bold">
						Vel Prom :
					</label>
					40 Km+\Hr.
				</Grid>
				<Grid item xs={12}>
					{" "}
					<label htmlFor="" className="textColorDarkGreen text-bold">
						Contacto:
					</label>{" "}
					591-7439588
				</Grid>
			</Grid>
		</>
	);
};
