import React from 'react'
import { Polyline } from 'react-leaflet'
const pathOptionsType={
         'BUS':{lineCap:'butt',dashArray:'0'}, //iF dasharray not set for bus, a weird bug setting up the old dashArray happens
         'WALK':{lineCap:'butt',dashArray:'4'}
   }
export const PolylineVariant = ({positions,routeType='BUS',color='gray'}) => {
     
    return (
        <Polyline pathOptions={{...pathOptionsType[routeType],color}} positions={positions}>
            
        </Polyline>
    )
}
