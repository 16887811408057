/* eslint-disable react/jsx-no-target-blank */
import './buttonBadge.css'

import PropTypes from 'prop-types'
import appStoreBadge from './appstoreBadge.svg'
import googlePlayBadge from './playstoreBadge.svg'

const ButtonBadge = ({ platform }) => {
    return (
        platform === "android"
            ?
            <a
                target="_blank"
                className="badge-link"
                href='https://play.google.com/store/apps/details?id=com.mos&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
            >
                <img
                    src={googlePlayBadge}
                    className="badge-img"
                    alt='Disponible en Google Play'
                />
            </a>
            :
            <a
                target="_blank"
                className="badge-link"
                href="https://apps.apple.com/us/app/cruzero/id1158164502?itsct=apps_box&amp;itscg=30200"
            >
                <img
                    src={appStoreBadge}
                    className="badge-img"
                    alt="Download on the App Store"
                />
            </a>
    );
}

ButtonBadge.propTypes = {
    platform: PropTypes.oneOfType(['android', 'ios'])
}

export default ButtonBadge;