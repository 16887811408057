import { useContext } from "react";
import { LoadingSpinnerContext } from "../context/LoadingSpinnerContext";

export const useLoadingWidget = () => {
	const { isLoading } = useContext(LoadingSpinnerContext);
	let loading = {
		isShown: isLoading.val,
		begin: () => {
			isLoading.set(true);
		},
		stop: () => {
			isLoading.set(false);
		},
		/**
		 * Yo can do all the loading flow with an asynchronous function
		 * @param {*} asyncFunction 
		 * @returns 
		 */
		doFlowOnFunction: async (asyncFunction) => {
			loading.begin();
			const res = await asyncFunction();
			loading.stop();
			return res;
		},
	};

	return { loading };
};
