import { useState } from "react";


const useSideBar=()=>{
    const [open, setOpen] = useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
      };
    
      const handleDrawerClose = () => {
        setOpen(false);
      };
    return {open,handleDrawerOpen,handleDrawerClose}
}
export default useSideBar