// Material Components
import { Fab, Grid } from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import AdSense from 'react-adsense';
import { ChevronRight, MyLocation } from "@material-ui/icons";
// Styles
import "leaflet/dist/leaflet.css";
import React, { useContext, useEffect } from "react";
// Leaflet Components
import {
	MapContainer,
	Marker,
	Polyline,
	Popup,
	TileLayer,
	Tooltip,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
// Others
import MatGeocoder from "react-mui-mapbox-geocoder";
import { leafletConstants } from "../../api/api.utils";
import originMarkerIconPng from "../../assets/icons/origin_pin.png";
import destinationMarkerIconPng from "../../assets/icons/destination_pin.png";
import { MainRouteContext } from "../../context/MainRouteContext";
import useCustomMap from "../../hooks/useCustomMap";
import AddBanner from "../AddBanner";

import BanderaIcon from "../Icons/BanderaIcon";
import {
	BusStopIcon,
	DestinationPinIcon,
	InterestPointIcon,
	OriginPinIcon,
} from "../Icons/leaflet-icons/CustomIcons";
import { ParadaBalloonIcon } from "../Icons/leaflet-icons/parkingMarker";
import PinPointIcon from "../Icons/PinPointIcon";
import InterestPointContent from "../InterestPointContent/InterestPointContent";
// Cruzero Components
import SideBarCustom from "../SideBar/SideBarCustom";
import "./MapView.css";
import { PolylineVariant } from "./PolylineVariant";
import useWindowDimensions from "../../hooks/useScreenSize";
import { MarkersContext } from "../../context/MarkersContext";
import { PolylineMarked } from "./PolylineMarked";
import { RouteCalculationMarkers } from "./RouteCalculationMarkers";
import { WayPointMarker } from "./WayPointMarker";
import { StaticPolyline } from "./StaticPolyline";
import { isMobile } from "react-device-detect";
const PIN_TYPES = {
	origin: "ORIGIN",
	destination: "DESTINATION",
};
// Main Component
/**
 *
 *
 *  ex activeMarkers: activeMarkers={{stops:true}}
 * @returns
 */

function SideBarButton(props) {
	return (
		<Fab
			color="primary"
			aria-label="add"
			style={{
				borderRadius: "0px 8px 8px 0px",
				position: "fixed",
				zIndex: "600",
				top: "42%",
				height: "16%",
				width: "32px",
			}}
			onClick={props.handleDrawerOpen}
		>
			<ChevronRight />
		</Fab>
	);
}

const MapView = ({
	sidebarChildren,
	activeMarkers = { poi: false, stops: false },
	LeftSideBarProps = { width: 410 },
}) => {
	const {
		classes,
		routeCalculationPoints,
		paradas,
		sidebarChildrenState,
		map,
		pois,
		setMap,
		drawers,
		selectedPoi,
		handlers,
	} = useCustomMap({ activeMarkers, sidebarChildren });
	const {
		mapController,
		selectedRouteGeom,
		selectedRoute,
		wayPoints,
		routeCalculationType,
	} = useContext(MainRouteContext);

	const { poiList, stopList, stopSearcherShow } = useContext(MarkersContext);
	const windowsSize = useWindowDimensions();
	const goToMyGeolocatedPosition = () => {
		var options = {
			enableHighAccuracy: true,
		};
		var err = () => {};
		navigator.geolocation.getCurrentPosition(setMapPosition, err, options);
	};

	const setMapPosition = (pos) => {
		console.log(pos.coords);
		var latlng = {
			lat: pos.coords.latitude,
			lng: pos.coords.longitude,
		};
		map.flyTo(latlng, 18);
	};

	const limeOptions = { color: "#249D39" };
	const geocoderApiOptions = {
		country: "bo",
	};
	//On create
	useEffect(() => {
		if (LeftSideBarProps.width > windowsSize.width) {
			LeftSideBarProps.width = windowsSize.width;
		}
		stopSearcherShow.set(activeMarkers.stops);
		if (map) {
			goToMyGeolocatedPosition();
		}
	}, []);

	
	const beginRouteCalculation = (pinType) => {
		return () => {
			const { lat, lng } = map.getCenter();

			if (pinType == PIN_TYPES.origin) {
				routeCalculationPoints.origin.set({ lat, long: lng });
				wayPoints.isSelectingOrigin.set(false);
			} else if (pinType == PIN_TYPES.destination) {
				routeCalculationPoints.destination.set({ lat, long: lng });
				wayPoints.isSelectingDestination.set(false);
			}
			drawers.left.handleDrawerOpen();
		};
	};
	const markerWidgetProps = {
		poi: {
			show: activeMarkers.poi,
			data: poiList.val,
			icon: { InterestPointIcon },
			coordAttributes: { latString: "poi_lat", lonString: "poi_lon" },
			handlers: {
				popup: {
					goOnTransport: handlers.clickGoOnTransport,
				},
				marker: { onClick: handlers.clickPoiMarker },
			},
		},
		stop: {
			show: activeMarkers.stops,
			data: stopList.val,
			icon: { BusStopIcon },
			coordAttributes: { latString: "stop_lat", lonString: "stop_lon" },
			handlers: {
				popup: {
					goOnTransport: handlers.clickGoOnTransport,
					nearRoutes: handlers.clickNearRoutes,
				},
				marker: { onClick: handlers.clickBusStopMarker },
			},
		},
	};
	const getStopMarkers = (activeMarkers, paradas, handleClickParada) => {
		return (
			<MarkersList
				data={paradas}
				icon={BusStopIcon}
				handleClick={handleClickParada}
				coordAttributes={{ latString: "stop_lat", lonString: "stop_lon" }}
			>
				{(item) => {
					return (
						<Popup>
							<Grid conta iner justify="center">
								<Button
									onClick={() => handlers.clickNearRoutes(item)}
									color="primary"
									variant="contained"
								>
									Ver Lineas{" "}
								</Button>
								<Button
									onClick={() => handlers.clickGoOnTransport(item)}
									color="primary"
									variant="contained"
								>
									Como llegar{" "}
								</Button>
							</Grid>
						</Popup>
					);
				}}
			</MarkersList>
		);
	};

	const getPoisMarkers = (activeMarkers, pois, handleClickPois) => {
		return activeMarkers?.poi ? (
			<MarkersList
				data={poiList.val}
				icon={InterestPointIcon}
				handleClick={handleClickPois}
				coordAttributes={{ latString: "poi_lat", lonString: "poi_lon" }}
			>
				{(item) => {
					return (
						<Popup>
							<Grid conta iner justify="center">
								{/* <Button
								onClick={() => handlers.clickNearRoutes(item)}
								color="primary"
								variant="contained"
							>
								Ver Lineas{" "}
							</Button> */}
								<Button
									onClick={() => handlers.clickGoOnTransport(item)}
									color="primary"
									variant="contained"
								>
									Como llegar{" "}
								</Button>
							</Grid>
						</Popup>
					);
				}}
			</MarkersList>
		) : null;
	};
	return (
		<>
			<SideBarButton handleDrawerOpen={drawers.left.handleDrawerOpen} />

			<SideBarCustom
				width={LeftSideBarProps.width}
				handleDrawerClose={drawers.left.handleDrawerClose}
				open={drawers.left.open}
				location="left"
			>
				{sidebarChildrenState}
			</SideBarCustom>
			<SideBarCustom
				handleDrawerClose={drawers.right.handleDrawerClose}
				open={drawers.right.open}
				location="right"
			>
				<InterestPointContent aPlace={selectedPoi}></InterestPointContent>
			</SideBarCustom>

			<MapContainer
				style={{ width: "100%", height: "calc(100vh - 64px)" }}
				center={[-17.783765929369313, -63.18112794115596]}
				zoom={10}
				scrollWheelZoom={true}
				whenCreated={(theMap) => {
					setMap(theMap);
					mapController.set(theMap);
				}}
			>
				<TileLayer
					attribution={leafletConstants.attribution}
					url={leafletConstants.url}
				/>

				<WayPointMarker
					icon={originMarkerIconPng}
					// message={"Seleccione para Buscar Paradas"}
					onClick={() =>
						handlers.clickSearchStops(
							map.getCenter().lat,
							map.getCenter().lng
						)
					}
					showBool={
						stopSearcherShow.val &&
						!wayPoints.isSelectingOrigin.val &&
						!wayPoints.isSelectingDestination.val
					}
				/>

				{/* ---------- Rutas estaticas  ----------*/}
				{selectedRouteGeom.val != undefined ? (
					<StaticPolyline
						selectedRoute={selectedRoute.val}
						selectedRouteGeom={selectedRouteGeom.val}
						type={routeCalculationType.val.transport_type_name}
					/>
				) : null}

				{/* ---------- RUTAS dinamicas (punto a punto b) ------------- */}
				<PolylineMarked routeData={selectedRoute.val?.data} />

				{/* Markers when the Origin and destiny are Selected */}
				<RouteCalculationMarkers
					routeCalculationPoints={routeCalculationPoints}
				/>

				{getStopMarkers(
					activeMarkers,
					stopList.val,
					handlers.clickBusStopMarker
				)}
				{getPoisMarkers(activeMarkers, pois, handlers.clickPoiMarker)}
			</MapContainer>

			{/* Centered Marker */}
			<WayPointMarker
				icon={originMarkerIconPng}
				message={"Seleccione Origen"}
				onClick={beginRouteCalculation(PIN_TYPES.origin)}
				showBool={wayPoints.isSelectingOrigin.val}
			/>
			<WayPointMarker
				icon={destinationMarkerIconPng}
				message={"Seleccione Destino"}
				onClick={beginRouteCalculation(PIN_TYPES.destination)}
				showBool={wayPoints.isSelectingDestination.val}
			/>

			<Fab
				onClick={goToMyGeolocatedPosition}
				className={classes.fab}
				color="primary"
			>
				<MyLocation />
			</Fab> 
			
			<AdSense.Google
  client='ca-pub-1361018247974206'
  slot='2655225563'
  style={{ width: "50%",maxWidth:'100%', height: 100, display: 'inline-block',position:'absolute',zIndex:1000,bottom:0,left:`${(100-50)/2}%`}}
  responsive='true'
  format={isMobile?'rectangle':'horizontal'}

/> 

			{/* <AddBanner /> */}
		</>
	);
};

export default MapView;

/**
 *Returns widgets containing markers
 * @param {*} markersCondition
 * @param {*} data
 * @param {*} handleClick what will happen when a marker is clicked
 */
const MarkersList = ({
	data,
	icon,
	handleClick,
	coordAttributes = { latString: "", lonString: "" },
	children = (item) => {},
}) => {
	return data?.map?.((item) => (
		<Marker
			eventHandlers={{
				click: (e) => {
					handleClick({ e, item });
				},
			}}
			icon={icon}
			position={[
				item[coordAttributes.latString],
				item[coordAttributes.lonString],
			]}
		>
			{children(item)}
		</Marker>
	));
};
