import {
	Box,
	ButtonBase,
	CardContent,
	CircularProgress,
	Grid,
	List,
	ListItem,
	ListItemText,
	makeStyles,
	Paper,
	Typography,
	useTheme,
	Button,
} from "@material-ui/core/";
import { BusLineIcon } from "./Icons/image-icons/ImageIconsList";
import { useStateObject } from "../hooks/useStateObject";
import { useFetchFunction } from "../hooks/useFetchFunction";
import TransportAPI from "../api/transport.api";
import { ImageIcon } from "./Icons/image-icons/ImageIcon";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: "center",
		color: theme.palette.text.secondary,
		backgroundColor: "#004017",
	},
}));

export const TransportTypesRow = ({ onClick }) => {
	const { res: transporTypesList } = useFetchFunction(TransportAPI.getTypes);
	//Adding the taxis manually
	//    useEffect(() => {
	// 		transporTypesList?.data?.push({id:-1,transport_type_icon: null,
	// 			transport_type_icon_url: "https://res.cloudinary.com/dhs9vk9r6/image/upload/v1621194454/trufi_d7731f002a.png",
	// 			transport_type_name: "TAXI",
	// 			transport_type_name_original:null})

	//    }, [])
	const taxiTransport = {
		id: -1,
		transport_type_icon: null,
		transport_type_icon_url:
			"https://res.cloudinary.com/dhs9vk9r6/image/upload/v1621194454/trufi_d7731f002a.png",
		transport_type_name: "TAXI",
		transport_type_name_original: null,
	};
	const classes = useStyles();
	return (
		<Grid container>
			{/* <Grid item xs={4} style={{ padding: "4px" }}>
				<ButtonBase
					onClick={() => {
						onClick(taxiTransport);
					}}
				>
					<Paper className={classes.paper}>
						<ImageIcon url={taxiTransport?.transport_type_icon_url} />
						<Typography
							style={{ fontFamily: "Ubuntu", color: "#8BAA06" }}
						>
							{taxiTransport.transport_type_name}
						</Typography>
					</Paper>
				</ButtonBase>
			</Grid> */}
			{transporTypesList?.data?.map?.((aTransportType, index) => {
				// console.log('RowForm',index);
				return (
					<Grid item xs={4} style={{ padding: "4px" }}>
						<ButtonBase
							onClick={() => {
								onClick(aTransportType);
							}}
						>
							<Paper className={classes.paper}>
								<ImageIcon
									url={aTransportType?.transport_type_icon_url}
								/>
								<Typography
									style={{ fontFamily: "Ubuntu", color: "#8BAA06" }}
								>
									{aTransportType.transport_type_name}
								</Typography>
							</Paper>
						</ButtonBase>
					</Grid>
				);
			})}
		</Grid>
	);
};
