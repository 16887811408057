/* eslint-disable jsx-a11y/alt-text */
import "./CardIcon.css";

import React from "react";
import { Card, CardContent, Paper } from "@material-ui/core";

const CardIcon = ({ title, description, children,onClick }) => {
	return (
		<img onClick={onClick}
			className="card-icon"
			src={require(`../icons/${title}.png`).default}
		/>
	);
};

export default CardIcon;
