import React, { useState } from "react";
import { useStateNamed } from "../hooks/useStateNamed";
//This context is for the routes that are calculated with custom points in the map
//---Context Creation---

const MainRouteContext = React.createContext({
	mapController: { val: [], set: () => {} }, //The map controller
	wayPoints: {
		origin: { val: { lat: 0, long: 0 }, set: () => {} },
		destination: { val: { lat: 0, long: 0 }, set: () => {} },
		isSelectingOrigin: { val: false, set: () => {} },
		isSelectingDestination: { val: false, set: () => {} },
	},
	routesList: { val: [], set: () => {} }, //All the routes with their data
	selectedRoute: { val: {}, set: () => {} }, //all the data of the current selected route
	selectedRouteGeom: { val: [], set: () => {} }, // the geom points (lat long) that are shown in the map
	routeCalculationType:{val:{}, set: () => {} }, //The type selected for calculating dynamic routes
	contextFunctions: { restart: () => {} },
});

const MainRouteContextProvider = (props) => {
	//---Context Data---

	const contextState = {
		// For route selection
		mapController: useStateNamed(),
		wayPoints: {
			origin: useStateNamed({ lat: 0, long: 0 }), //origin coords
			destination: useStateNamed({ lat: 0, long: 0 }), //destination coords
			isSelectingOrigin: useStateNamed(false), //For show in the map the marker to select the point
			isSelectingDestination: useStateNamed(false), //For show in the map the marker to select the point
		},

		routesList: useStateNamed([]), //List of all routes
		//Note: the next attributes are different because there are different formas of Geom in two api endpoints
		selectedRoute: useStateNamed({}), //All the data of a selected route
		selectedRouteGeom: useStateNamed([]), //Only the lat long points in array of a selected route
		routeCalculationType:useStateNamed({}), //The type that is selected before calculate routes
		contextFunctions: {
			restart: () => {
				contextState.wayPoints.origin.restart()
				contextState.wayPoints.destination.restart()
				contextState.wayPoints.isSelectingOrigin.restart();
				contextState.wayPoints.isSelectingDestination.restart();
				contextState.routesList.restart()
				contextState.selectedRoute.restart()
				contextState.selectedRouteGeom.restart()
			},
		},
	};

	//example of selectedRouteGeom
	//[
	// [
	//     -63.10822,
	//     -17.78643
	// ],
	// [
	//     -63.10823,
	//     -17.78596
	// ]]

	//Context Provider with the data
	return (
		<MainRouteContext.Provider value={contextState}>
			{props.children}
		</MainRouteContext.Provider>
	);
};

export { MainRouteContext, MainRouteContextProvider };
