import { defaultHeaders, genericDestroy, url, genericGet } from "./api.utils";
const routeAPI = {
	// /**
	//  * Weird old get routes
	//  * @param {*} latfrom
	//  * @param {*} lonfrom
	//  * @param {*} latto
	//  * @param {*} lonto
	//  */
	// getAllRoutes: async (latfrom, lonfrom, latto, lonto) => {
	// 	try {
	// 		const response = await axios.get(
	// 			`${url}/otp?from_lat=${latfrom}&from_lng=${lonfrom}&to_lat=${latto}&to_lng=${lonto}&polyline=false`
	// 		);
	// 		if (response.data && response.data.length > 0) {
	// 			console.log(response.data);
	// 			setContextState((state) => ({
	// 				...state,
	// 				route: [...response.data[0]],
	// 			}));
	// 			setRutas((rutas) => ({ ...rutas, routes: [...response.data] }));
	// 		} else {
	// 			setContextState((state) => ({ ...state, route: [...[]] }));
	// 			setRutas((rutas) => ({ ...rutas, routes: [...[]] }));
	// 		}
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// },
	getRouteCalculation: async (from_lat, from_lng, to_lat, to_lng, city) => {
		try {
			const res = await genericGet(`otp/v2`, {
				from_lat,
				from_lng,
				to_lat,
				to_lng,
				polyline: false,
				city,deviceTypeId:1
			});
			return res;
			// console.log(res);
		} catch (error) {
			console.log(error);
		}
	},
	getTaxiCalculation: async (
		origin_lat,
		origin_lon,
		destination_lat,
		destination_lon
	) => {
		try {
			const res = await genericGet(`taxi-route`, {
				origin_lat,
				origin_lon,
				destination_lat,
				destination_lon,
			});
			return res;
		} catch (error) {
			console.log(error);
		}
	},
	getTaxiCalculationFromMapbox: async (
		origin_lat,
		origin_lon,
		destination_lat,
		destination_lon
	) => {
		try {
			const res = await fetch(
				`https://api.mapbox.com/directions/v5/mapbox/driving/${origin_lon}%2C${origin_lat}%3B${destination_lon}%2C${destination_lat}?alternatives=false&geometries=geojson&language=en&overview=simplified&steps=true&access_token=pk.eyJ1IjoidmxhZGFuZXNlIiwiYSI6ImNrbWNud3IxZDBlcnAydm1jejgzanRjb3oifQ.yq4nrvkPPITU-hQSWs5Lew`,{method:'GET'}
			);
			return  await res.json();
		} catch (error) {
			console.log(error);
		}
	},
};

export default routeAPI;
