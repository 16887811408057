/* eslint-disable jsx-a11y/img-redundant-alt */
// LIBRARIES
import { useHistory } from "react-router-dom";
import React from "react";
import { Grid } from "@material-ui/core";
import video from "../../assets/pexels-george-morina-5687581.mp4";
import { Link, useRouteMatch } from "react-router-dom";
// STYLES
import "./HomePage.css";
import "../../components/home/vectors/home.css";
import "../../components/home/vectors/TextStyle.css";

// COMPONENTS
import CardIcon from "../../components/home/cards/CardIcon";
import CardImage from "../../components/home/cards/CardImage";

// Images
import organizeURL from "../../assets/icons/home/web_home_organize.png";
import routeURL from "../../assets/icons/home/web_home_route.png";
import shareURL from "../../assets/icons/home/web_home_share.png";

const miniCardsList = [
	{ title: "mapa",url:"calculate-routes" },
	{ title: "lineas",url:"routes" },
	{
		title: "destinos",url:"interest"
	},
	{
		title: "agregar rutas",url:"app"
	},
];

const bigCardsList = [
	{
		variant: 0,
		animation: "animatedCardL",
		title: "Organiza",
		imgSrc:
			"https://images.pexels.com/photos/2043616/pexels-photo-2043616.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
		description: [
			"Planifica tu día",
			"Mide tus distancias",
			"Organiza tus tiempos",
		],
		circleImgURL: organizeURL,
	},
	{
		variant: 1,
		animation: "animatedCardR",
		imgSrc:
			"https://cdn.pixabay.com/photo/2012/12/24/08/39/bus-stop-72171_1280.jpg",
		title: "Rutas",
		description: [
			"Sacale provecho",
			"Organiza tus viajes",
			"Conoce todos los caminos",
		],
		circleImgURL: routeURL,
	},
	{
		variant: 0,
		animation: "animatedCardL",
		title: "Comparte",
		imgSrc:
			"https://cdn.pixabay.com/photo/2015/07/15/11/52/map-846083_1280.jpg",
		description: [
			"Por seguridad comparte tus viajes",
			"Guarda tus destinos",
			"Cuenta tus experiencias en el viaje",
		],
		circleImgURL: shareURL,
	},
];

const HomePage = () => {
	const history = useHistory();
	return (
		<>
			<Grid container justify="center" direction="row" alignItems="center">
				<Grid item xs={12}>
					<div className="container">
						<h1 className="title">CRUZERO</h1>
						<video loop muted autoPlay className="video">
							<source src={video} type="video/mp4" />
						</video>
					</div>
				</Grid>
				<Grid item xs={12}>
					<div
						className="container"
						style={{
							height: "90%",
							backgroundColor: "#CCCCCC",
						}}
					>
						<img
							src={require("./text.png").default}
							alt="#"
							style={{
								width: "20%",
								transform: "rotate(180deg) scale(.7)",
							}}
						/>
						<p className="container text">
							Nuestro único objetivo es ayudar a todos los usuarios o
							turistas que vayan a ir en Bus o Micro en la ciudad,
							conocer que línea puede tomar, donde esperar y que rutas le
							conviene seguir, utilízala y ayúdanos a almentarla con tu
							experiencia
						</p>
						<img
							src={require("./text.png").default}
							alt="#"
							style={{ width: "20%", transform: "scale(.7)" }}
						/>
					</div>
				</Grid>
				<Grid item xs={12}>
					<div
						className="container"
						style={{ backgroundColor: "#C1C1C1" }}
					>
						{miniCardsList.map((card) => (
							<CardIcon title={card.title} onClick={()=>history.push(card.url)} />
						))}
					</div>
				</Grid>
			</Grid>
			<Grid container justify="center">
				{bigCardsList.map((cardData, index) => {
					return <CardImage isOdd={index % 2 === 1} {...cardData} />;
				})}
			</Grid>
			<footer style={{textAlign:"right",padding:"0.5rem"}} href=""><Link to={'condiciones'}> <h5> Terminos y Condiciones </h5></Link></footer>
		</>
	);
};

export default HomePage;
