import {
	Box,
	ButtonBase,
	CardContent,
	CircularProgress,
	Grid,
	List,
	ListItem,
	ListItemText,
	makeStyles,
	Paper,
	Typography,
	useTheme,
	Button,
} from "@material-ui/core/";
import { useContext, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import TransportAPI from "../../../api/transport.api";
import CruzeroCard from "../../../components/CruzeroCard";
import { LineInformation } from "../../../components/DataContainers/LineInformation";
import {
	BusLineIcon,
	NoteIcon,
	RouteIcon,
} from "../../../components/Icons/image-icons/ImageIconsList";
import { TransportTypesRow } from "../../../components/TransportTypesRow";
import { GlobalDialogContext } from "../../../context/GlobalDialogContext";
import { MainRouteContext } from "../../../context/MainRouteContext";
import { MarkersContext } from "../../../context/MarkersContext";
import { useFetchFunction } from "../../../hooks/useFetchFunction";
import { useGlobalDialog } from "../../../hooks/useGlobalDialog";
import { useLoadingWidget } from "../../../hooks/useLoadingSpinner";
import { useStateObject } from "../../../hooks/useStateObject";
import { LinesList } from "./LinesList";
import { PoisTimeline } from "./PoisTimeline";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: "center",
		color: theme.palette.text.secondary,
		backgroundColor: "#004017",
	},
}));

/**
 * Component Transportation Types
 * @returns
 */

function SelectedRouteOptions({ main, handlers }) {
	return (
		<Grid container justify="center">
			<Grid
				item
				md={12}
				xs={12}
				style={{
					textAlign: "center",
				}}
			>
				<h5
					style={{
						color: main,
					}}
				>
					Opciones de Linea Seleccionada
				</h5>
			</Grid>
			<Grid sm={4} item md={3} xs={4}>
				<Button onClick={handlers.clickRouteOption}>
					<RouteIcon props />
				</Button>
			</Grid>
			<Grid item sm={4} md={3} xs={4}>
				<Button>
					<NoteIcon />
				</Button>
			</Grid>
		</Grid>
	);
}

const TransportSelection = ({ initialIndex = 0, routeList = [] }) => {
	const theme = useTheme();
	const { loading } = useLoadingWidget();

	const { selectedRouteGeom, selectedRoute } = useContext(MainRouteContext);
	const { poiList } = useContext(MarkersContext);
	const globalDialog = useGlobalDialog();
	const [state, setStateStaticRoutes, modifyState] = useStateObject({
		indexSwipeableView: initialIndex, //Index of swipeableview

		selectedTransportType: null,
		routeList: routeList,
	});

	const selectTransportType = async (selectedTransport) => {
		//change the index of the swipeableView
		//set the selected Route
		//HARDCODING FETCH TO ONLY MICRO : FOR NOW
		if (selectedTransport.transport_type_name != "MICRO") {
			selectedTransport.id = -10;
		}

		await listRoutes(selectedTransport);

		modifyState({
			indexSwipeableView: 1 - initialIndex,
			selectedTransportType: selectedTransport,
		});
	};
	const listRoutes = async (selectedTransportParam) => {
		console.log({ selectedTransportParam });
		const routesRes = await loading.doFlowOnFunction(
			async () => await TransportAPI.listAllOfType(selectedTransportParam.id)
		);
		//console.log(routesRes);
		modifyState({
			routeList: routesRes.data,
		});
	};

	const handlers = {
		/**
		 * Goes back in the menu slide
		 */
		clickBack: () => {
			modifyState({
				indexSwipeableView: state.indexSwipeableView - 1, //go Back in swipable views
				selectedTransportType: null, //Clean the title
			});
			selectedRouteGeom //clean the selections
				.set([]);
			poiList.restart();
		},
		clickRouteOption: () => {
			modifyState({ indexSwipeableView: 2 });
		},
		clickTransportPhoto: ({ photo }) => {
			globalDialog.showWidget(<img class="img-fluid" src={photo} alt="" />);
			console.log("Showing dialog?");
		},
	};

	return (
		<div>
			{state.indexSwipeableView > initialIndex ? (
				<Button
					variant="contained"
					color="primary"
					onClick={handlers.clickBack}
				>
					{"Volver"}
				</Button>
			) : null}
			<CruzeroCard
				title={
					state.selectedTransportType?.transport_type_name
						? "Lista de " +
						  state.selectedTransportType?.transport_type_name
						: "Seleccionar Medio de Transporte"
				}
				color="#004017"
			>
				<SwipeableViews
					disabled
					axis={theme.direction === "rtl" ? "x-reverse" : "x"}
					index={state.indexSwipeableView}
				>
					<Box display="flex">
						<TransportTypesRow onClick={selectTransportType} />
					</Box>
					<Box>
						<LinesList
							routeList={state.routeList}
							onLineClick={handlers.clickRouteOption}
						/>
					</Box>
					<Box>
						<>
							<CruzeroCard>
								<LineInformation
									onClickPhoto={() =>
										handlers.clickTransportPhoto({
											photo: selectedRoute.val?.Pictures?.[0]
												?.pic_url,
										})
									}
									pic_thumbnail_url={
										selectedRoute.val?.Pictures?.[0]
											?.pic_thumbnail_url
									}
									schedule={{
										begin: "05 am",
										end: "10 pm",
										frequency: "10 min",
									}}
									transport_name={selectedRoute?.val.transport_name}
								/>
							</CruzeroCard>
							<PoisTimeline />
						</>
					</Box>
				</SwipeableViews>
			</CruzeroCard>
		</div>
	);
};

export { TransportSelection };
