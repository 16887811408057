import NavBarWidget from './components/navigation/NavBarWidget';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import './App.css';
import PersonalizedRoutes from './pages/PersonalizedRoutes/PersonalizedRoutes';
import InterestPoints from './pages/InterestPoint/InterestPoints';
import HomePage from './pages/Home/HomePage';
import Paradas from './pages/Paradas/Paradas';
import AppPage from './pages/mobileApp/appPage';
import { StaticRoutes } from './pages/StaticRoutes/StaticRoutes';
import { LoadingSpinnerContextProvider } from './context/LoadingSpinnerContext';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import { MarkersContextProvider } from './context/MarkersContext';
import { GlobalDialogContextProvider } from './context/GlobalDialogContext';
import { DrawerContextProvider } from './context/DrawerContext';
import React from 'react';
import { TermsAndConditions } from './pages/TermsAndConditions/TermsAndConditions';
function App() {

  //  theme link
  
  // https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=3ad171&secondary.color=16d190&primary.text.color=ffffff&secondary.text.color=0d2b12

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#8BAA06',
        contrastText: '#FFF',
        light: '#77ffa1',
        dark: 'rgb(0, 64, 23);'

      },
      secondary: {
        // This is green.A700 as hex.
        main: '#16d190',
        contrastText: '#0d2b12',
        light: '#77ffa1',
        dark: '#009f62'
      },
    }
  })

  return (
    
    <BrowserRouter>
  
    <LoadingSpinnerContextProvider>
     <MarkersContextProvider> 
        <GlobalDialogContextProvider>
          <DrawerContextProvider>

          
      <ThemeProvider theme={theme}>
        <NavBarWidget></NavBarWidget>
        <Switch>
          <Route path="/app" component={AppPage} />
          <Route path="/home" component={HomePage} />
          <Route path="/paradas" component={Paradas} />
          <Route path="/calculate-routes" component={PersonalizedRoutes} />
          <Route path="/routes" component={StaticRoutes} />
          <Route path="/interest" component={InterestPoints} />
          <Route path="/condiciones" component={TermsAndConditions} />
          <Redirect from="*" to="/home" />
        </Switch>
      </ThemeProvider>
      </DrawerContextProvider>
      </GlobalDialogContextProvider>
      </MarkersContextProvider>
      </LoadingSpinnerContextProvider>
    </BrowserRouter>

  );
}

export default App;
