import MapView from "../../components/MapView/MapView";
import { SelectPoiType } from "./containers/SelectPoiType";
import { MainRouteContextProvider } from "../../context/MainRouteContext";

const InterestPoints = () => {
    return (
        <MainRouteContextProvider>
            <MapView LeftSideBarProps={{width:410}}  activeMarkers={{poi:true}} 
             sidebarChildren={<SelectPoiType />}
            >
            </MapView>
        </MainRouteContextProvider>
    );
}

export default InterestPoints;