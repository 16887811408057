import { ButtonBase, Grid, Icon, makeStyles, Paper, useTheme } from "@material-ui/core";
import { BorderStyle } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from 'react-swipeable-views-utils';
import CruzeroCard from "../CruzeroCard";
import "./InterestPointContent.css";
let place = {
	title: "Cine Center",
	category: "Cines",
	previewImage:
		"http://www.dondebolivia.com/wp-content/uploads/cache/images/LOGO_CINE_CENTER2/LOGO_CINE_CENTER2-621804702.jpg",
	images: [
		{
			url: "https://www.paginasiete.bo/u/fotografias/m/2020/9/3/f608x342-323580_353303_20.jpg",
		},
		{
			url: "https://www.paginasiete.bo/u/fotografias/m/2020/9/3/f608x342-323580_353303_20.jpg",
		},
	],
	description:
		"Un cine de película, Cine Center es el complejo cinematográfico más grande e importante de Bolivia, ofreciendo siempre las películas más esperadas.",
	schedule: [
		{ days: "Lun a Vie", time: "18:00-22:30" },
		{ days: "Sab y Dom", time: "16:00-01:00" },
	],
	address: "Calle Libertad #37 entre junin y ayacucho",
	contact: "wa.me/5917439588 (3) 384-7123",
	social: ["facebook/link", "instagram/link"],
};
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: "center",
		color: theme.palette.text.secondary,
	},
}));
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const InterestPointContent = ({ aPlace }) => {
    const [index,setIndex]=useState(0);
    const handleChangeIndex = index => {
        setIndex(
          index
        );
      };
	useEffect(() => {
		if (aPlace.poi_name != null) {
			place = {
				title: aPlace?.poi_name,
				category: aPlace?.PoiType?.type_name,
				previewImage: aPlace?.Pictures[0]?.pic_url,
				images: aPlace?.Pictures,
				description: aPlace?.poi_description,
				// schedule: [
				// 	{ days: "Lun a Vie", time: "18:00-22:30" },
				// 	{ days: "Sab y Dom", time: "16:00-01:00" },
				// ],
				address: aPlace?.poi_direction,
				contact: aPlace?.poi_phone_number,
				social: aPlace?.poi_website,
			};
		}
	}, [aPlace]);
	const classes = useStyles();
    const theme= useTheme();
	return (
		<div>
			<CruzeroCard title="Datos del Lugar" color="#004017">
           
            <AutoPlaySwipeableViews index={index} onChangeIndex={handleChangeIndex}>

       
					{place.images.map((anImage) => (
				
							<img 
                                
                                height="200rem"
								width="100%"
								style={{ objectFit: "cover" }}
								src={anImage.pic_url}
								alt=""
							/>
					

					))}
                      </AutoPlaySwipeableViews>
			
				<Grid container>
				
					<Grid item sm={12}>
						<p style={{color:theme.palette.primary.main}} className="PlaceTitle">{place.title}</p>
						
					</Grid>
				</Grid>

				<Grid style={{color:"white"}} container>
					<p>{place.description}</p>
				</Grid>
			
			</CruzeroCard>
			<CruzeroCard title="Datos del lugar" color="#8baa06" titleColor={theme.palette.primary.contrastText}>
				{/* <p>
					<label className="GeneralInformationTitle">
						Horarios de atencion:
					</label>
					{place.schedule.map((scheduleItem) => (
						<p>
							{scheduleItem.days}: {scheduleItem.time}
						</p>
					))}
				</p> */}
			<CruzeroCard title="Horarios de atencion" >
				<p>
					<label className="GeneralInformationTitle">Direccion: </label>{" "}
					<label>{place.address}</label>
				</p>
				{place.contact != null ? (
					<p>
						<label className="GeneralInformationTitle">Contacto: </label>{" "}
						<label>{place.contact}</label>
					</p>
				) : null}
				{place.social != null ? (
					<p>
						<label className="GeneralInformationTitle">Redes: </label>{" "}
						<p>{place.social}</p>
					</p>
				) : null}
			</CruzeroCard>
			</CruzeroCard>
		</div>
	);
};

export default InterestPointContent;

