import L from "leaflet";
import parkingUrl from "../../../assets/parkingMarker.svg";
import parkingBalloonUrl from "../../../assets/icon_Paradas_globo.png";
import { createIcon } from "./iconCreator";
export  const ParadaIcon = createIcon({iconUrl:parkingUrl});

export const ParadaBalloonIcon = new L.Icon({
	iconUrl: parkingBalloonUrl,
	iconRetinaUrl: parkingBalloonUrl,
	iconAnchor: null,
	popupAnchor: null,
	shadowUrl: null,
	shadowSize: null,
	shadowAnchor: null,
	iconSize: new L.Point(25, 25),
	// className: 'leaflet-div-icon'
});
