import {
	Grid,
	List,
	ListItem,
	ListItemText,
	useTheme,
} from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { BusIconSVG } from "../../../components/Icons/svg-icons/BusIconSVG";
import { MainRouteContext } from "../../../context/MainRouteContext";
import stylesCSS from "./LinesList.module.css";
import { ZebraList } from "../../../components/DataContainers/ZebraList";
import TransportAPI from "../../../api/transport.api";
import { useLoadingWidget } from "../../../hooks/useLoadingSpinner";
import { CityDrawCard } from "../../../components/CityDrawCard/CityDrawCard";
import { DrawerContext } from "../../../context/DrawerContext";
import { isMobile } from "react-device-detect";
/**
 *
 * Widget that contain the list of routes, based on the selected Transport Type
 */
export const LinesList = ({ routeList, onLineClick = () => {} }) => {
	const { selectedRouteGeom, selectedRoute } = useContext(MainRouteContext);
	const {drawers}=useContext(DrawerContext);
	const theme = useTheme();
	const { loading } = useLoadingWidget();
	const handlers = {
		clickListItem: async (busLine) => {
						
			const res =await loading.doFlowOnFunction(async() => {
				return await TransportAPI.getOne(busLine?.id);
			});
			

			//Inverted Lat Long Because its given like that in response
			const selectedRouteGeomSwap =
				res?.data[0]?.Lines?.[0]?.Shape?.shape_geom?.coordinates.map(
					(item) => [item[1], item[0]]
				);
			selectedRoute.set(res.data[0]);
			selectedRouteGeom.set(selectedRouteGeomSwap);
			if(isMobile){
				drawers.left.handleDrawerClose();
			}
			
		},
	};
	if(routeList.length>0)
	return (
		<ZebraList
			color="#004017"
			dataArray={routeList}
			handlers={{
				clickListItem: (aBusLine) => {
					handlers.clickListItem(aBusLine);
					onLineClick();
				},
			}}
		>
			{(aBusLine) => {
				return (
					<Grid container justify="space-evenly">
						<Grid item sm={4} xs={4} md={4} alignItems="center">
							<BusIconSVG color={theme.palette.primary.main} />
						</Grid>
						<Grid item sm={4} xs={4} md={4}>
							<ListItemText
								style={{ color: "white" }}
								primary={aBusLine.transport_name}
							/>
						</Grid>
					</Grid>
				);
			}}
		</ZebraList>
	);
	//else
	return (
		<CityDrawCard title={'Medio de Transporte no disponible'} description={'El medio de transporte no se encuentra disponible en este momento'}/>
	)
};
function getCurrentClassName(index) {
	return index % 2 == 0 ? stylesCSS.listBackgroundVariant : "";
}
