import "./navigation.css";

import logo from "./logo.png";
import { Link, useRouteMatch } from "react-router-dom";

import {
	Icon,
	List,
	Button,
	AppBar,
	Select,
	Toolbar,
	ListItem,
	MenuItem,
	CardMedia,
	InputLabel,
	Typography,
	IconButton,
	FormControl,
	ListSubheader,
} from "@material-ui/core";
import { useGlobalDialog } from "../../hooks/useGlobalDialog";
import { useEffect, useState } from "react";

import useSideBar from "../../hooks/useSideBar";
import SideBarCustom from "../SideBar/SideBarCustom";

const menuItems = [
	{ url: "/home", title: "Inicio" },
	{ url: "/calculate-routes", title: "Calcular Rutas" },
	{ url: "/routes", title: "Rutas" },
	{ url: "/paradas", title: "Paradas" },
	{ url: "/interest", title: "Lugares de Interés" },
	{ url: "/app", title: "App" },
];
const MyLink = ({ to, title, onClick, children }) => {
	let match = useRouteMatch({ path: to });
	return (
		<Link to={to} title={title} style={{ textDecoration: "none" }}>
			<Button
				onClick={onClick}
				className={match ? "nav-item active" : "nav-item"}
			>
				{children ?? title}
			</Button>
		</Link>
	);
};

const CustomAppBar = () => {
	const [showDrawer, setShowDrawer] = useState(false);
	const { handleDrawerClose, handleDrawerOpen, open } = useSideBar();
	const { showWidget } = useGlobalDialog();

	const handleResize = () => {
		if (window.innerWidth <= 600) {
			setShowDrawer(true);
		} else {
			setShowDrawer(false);
		}
	};
	const handlers = {
		mailTo: (e, aMailToRef) => {
			window.location = aMailToRef;
			showWidget(
				<h3>{"Puede enviar sus sugerencias a sugerencias@cruzero.net"}</h3>
			);
			e.preventDefault();
		},
	};
	useEffect(() => {
		if (window.innerWidth <= 600) {
			setShowDrawer(true);
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div>
			<AppBar position="sticky" elevation={1}>
				<Toolbar className="nav-menu" variant="regular">
					{showDrawer ? (
						<div className="nav-items">
							<Link to="/" title="cruzero">
								<CardMedia id="logo" component="img" src={logo} />
							</Link>

							<IconButton onClick={handleDrawerOpen} edge="end">
								<Icon style={{ fontSize: "4vh", color: "black" }}>
									menu
								</Icon>
							</IconButton>
						</div>
					) : (
						<div className="nav-items">
							<Link to="/" title="cruzero">
								<CardMedia id="logo" component="img" src={logo} />
							</Link>
							{menuItems.map((menuItem) => (
								<MyLink to={menuItem.url} title={menuItem.title} />
							))}
							<MyLink
								onClick={(e) =>
									handlers.mailTo(e, "mailto:sugerencias@cruzero.net")
								}
								children={
									<Icon style={{ fontSize: "4vh", color: "black" }}>
										mail
									</Icon>
								}
								title="Sugerencias"
							/>

							<div style={{ width: "16px" }}></div>
							{/* <FormControl style={{ minWidth: 120 }}>
								<InputLabel htmlFor="grouped-select">
									Locación
								</InputLabel>
								<Select defaultValue="" id="grouped-select">
									<MenuItem value="">
										<em>None</em>
									</MenuItem>
									<ListSubheader>Latinoamerica</ListSubheader>
									<MenuItem value={1}>Santa Cruz, Bolivia</MenuItem>
									<MenuItem value={2}>Cochabamba, Bolivia</MenuItem>
								</Select>
							</FormControl> */}
						</div>
					)}
				</Toolbar>
			</AppBar>
			<SideBarCustom
				open={open}
				handleDrawerClose={handleDrawerClose}
				location="top"
			>
				<List>
					{menuItems.map((menuItem, i) => (
						<Link to={menuItem.url} style={{ textDecoration: "none" }}>
							<ListItem
								button
								onClick={handleDrawerClose}
								key={i + "ListDrawer"}
							>
								<Typography style={{ color: "white" }}>
									{menuItem.title}
								</Typography>
							</ListItem>
						</Link>
					))}
				</List>
				{/* <InputLabel htmlFor="grouped-select">Grouping</InputLabel> */}
			</SideBarCustom>
		</div>
	);
};

export default CustomAppBar;
