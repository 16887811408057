import L from "leaflet";

import placeBallonnURL from "../../../assets/icon_lugares_globo.png";
import poiURL from "../../../assets/icons/places.png";
import poiv2URL from "../../../assets/icons/placesv2.png";
import poiv3URL from "../../../assets/icons/placesv3.png";
import poiv3SVGURL from "../../../assets/icons/placesv3.svg";

import stopURL from "../../../assets/icons/stop.png";
import destinationPinURL from "../../../assets/icons/destination_pin.png";
import originPinURL from "../../../assets/icons/origin_pin.png";

import { createIcon } from "./iconCreator";
const  poiv4URL = "https://res.cloudinary.com/dhs9vk9r6/image/upload/v1619961634/icon_Lugares_globo_68fa5195e3.png";
export const InterestPointBalloonIcon = createIcon({iconUrl:placeBallonnURL,size:45});
export const InterestPointIcon = createIcon({iconUrl:poiv4URL,size:45});
export const BusStopIcon = createIcon({iconUrl:stopURL,size:45});
export const OriginPinIcon = createIcon({iconUrl:originPinURL,size:45});
export const DestinationPinIcon = createIcon({iconUrl:destinationPinURL,size:45});

 
