import { genericGet, url } from "./api.utils";
import axios from "axios";
const getHeaders = () => {
	return {
		Accept: "application/json",
		"Content-Type": "application/json",
	};
};
const paradasAPI = {
	getParadas: async () => {
		let res = await genericGet(`city/stops`, { city_id: 1 });
		console.log(res);
		res.data = [];
		return res.data;
	},
	getNearLocation: async ({ lat, lon }) => {
		const res = await genericGet(`city/stops/near`, {
			city_id: 1,
			lat,
			lon,
			distance: 400,
		});
		return res;
	},
	getRoutesNearStops: async ({ stop_id, city_id }) => {
		const res = await genericGet(`city/stops/transport/near`, {
			stop_id,
			city_id,
			distance: 15,
		});
		return res;
	},
};

export default paradasAPI;
