import React, { useState } from 'react';
import {
  Fab,
  Drawer,
  IconButton,
  makeStyles,
} from "@material-ui/core/";
import {
  ArrowBack
} from "@material-ui/icons";
import useSideBar from '../../hooks/useSideBar';
const drawerWidth = 340;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "rgb(0, 64, 23);"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }
}));
const SideBarCustom = ({children,anchor,open,handleDrawerClose,location,width=340}) => {

  const classes = useStyles();
    return (
        <Drawer
        
        variant="persistent"
        anchor={location}
        open={open} 
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        PaperProps={{style:{width:width}}}
        color="primary"
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ArrowBack style={{ color: 'white'}}/>
          </IconButton>
        </div>
        {children}
      </Drawer>
    );
}

export default SideBarCustom;
