import React from "react";
import { ImageIcon } from "./ImageIcon";
import BusLineURL from "../../../assets/icons/lines.png";
import RouteURL from "../../../assets/icons/route.png";
import NoteURL from "../../../assets/icons/note.png";
import stopURL from "../../../assets/icons/stop.png";
export const BusLineIcon = () => {
	return <ImageIcon url={BusLineURL} />;
};
export const RouteIcon = () => {
	return <ImageIcon url={RouteURL} />;
};

export const NoteIcon = () => {
	return <ImageIcon url={NoteURL} />;
};

export const BusStopIcon=()=>{
	return <ImageIcon url={stopURL} />;
}
