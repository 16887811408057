import { useTheme } from "@material-ui/core";
import React from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { transportDefaultColors } from "../../utils";
import { BusDivIcon, WalkDivIcon } from "../Icons/leaflet-icons/CustomDivIcons";
import { BusStopIcon } from "../Icons/leaflet-icons/CustomIcons";
import { BusIconSVG } from "../Icons/svg-icons/BusIconSVG";
import { PolylineVariant } from "./PolylineVariant";
import { useGlobalDialog } from "../../hooks/useGlobalDialog";
//
const iconFunction = {
	BUS: BusDivIcon, //iF dasharray not set for bus, a weird bug setting up the old dashArray happens
	WALK: WalkDivIcon,
};

export const PolylineMarked = ({ routeData = [] }) => {
	const globalDialog = useGlobalDialog();
	let i = 0;
	/**
	 * Returns a color for the transport type
	 * @param {*} type
	 * @param {*} defaultColor default color if the type is not transport
	 * @returns
	 */
	const handlers={
		clickTransportIcon:(aRoute)=> {
			if(aRoute.type!="WALK"){
				console.log("Mostrando imagen de micro");
				globalDialog.showWidget(aRoute.pic_url!=undefined?<img class="img-fluid" src={aRoute.pic_url} alt="" />:<>{`Imagen de ${aRoute.route_agency } no disponible`}</>);
			}
		}
	}
	const getColor = (type, defaultColor) => {
		if (type != "WALK") {
			return transportDefaultColors[i % 3];
		} else {
			return defaultColor;
		}
	};
	return routeData?.map?.((anItem, index) => {
		if (anItem.type != "WALK") {
			i++;
		}
		if (true) {
			return (
				<>
					{" "}
					<PolylineVariant
						routeType={anItem.type}
						positions={anItem?.geom}
						color={getColor(anItem.type, "gray")}
					/>
					<Marker
						position={anItem.geom[0]}
						eventHandlers={{
							click: (e) => {
								handlers.clickTransportIcon(anItem);
							},
						}}
					
						icon={iconFunction[anItem.type]({
							color: getColor(anItem.type, "gray"),
						})}
					>
						<Tooltip direction="top" offset={[16, -30]} opacity={1}>
							<h4>
								{anItem.type == "WALK" ? "caminar" : anItem.route_agency}
							</h4>
						</Tooltip>{" "}
					</Marker>
				</>
			);
		}
	});
};
