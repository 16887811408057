
import { createContext } from "react";
import { GlobalDialog } from "../components/GlobalDialog/GlobalDialog";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import { useStateNamed } from "../hooks/useStateNamed";

export const GlobalDialogContext = createContext({
	open: { val: false, set: () => {} },
	children: { val: <></>, set: () => {} },
});

export const GlobalDialogContextProvider = ({ children }) => {
	const contextState = {
		open: useStateNamed(false),
		children: useStateNamed(<></>),
	};
	return (
		<GlobalDialogContext.Provider value={contextState}>
			<GlobalDialog/>
			{children}
		</GlobalDialogContext.Provider>
	);
};
