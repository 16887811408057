import { Group, Person } from "@material-ui/icons";
import React from "react";

export const TaxiInformation = ({
	duration,
	//priceOne,
	//priceMany,
	distance,
    textColor
}) => {

	const km=(distance / 1000).toFixed(2)
	const minutes=duration / 60;
	const priceOne = ((minutes * (km*0.22))/2 + 8);
	const priceMany = ((minutes * (km*0.27))/2 + 10);
	return (
		<div style={{borderColor:textColor,borderStyle:"solid",padding:"1rem"}}>
            <h5 style={{ color: textColor }}>Informacion de Taxi</h5>
			<p>
				<label style={{ color: textColor }}>
					{`Tiempo: ${(minutes).toFixed(1)}  min.`}
				</label>{" "}
			</p>
			<p>
				<label style={{ color: textColor }}>
				
					{`Tarifa:	` }<Person/>{` : ${(priceOne).toFixed(1)} Bs	│	`} <Group/>{` : ${(priceMany).toFixed(1)}  Bs`}
				</label>{" "}
			</p>
			<p>
				<label style={{ color: textColor }}>
					{`Distancia: ${km}  Km.`}
				</label>{" "}
			</p>
		</div>
	);
};
