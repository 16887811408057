import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineSeparator,
	TimelineOppositeContent,
} from "@material-ui/lab";
import React, { useContext } from "react";
import { MainRouteContext } from "../../../context/MainRouteContext";
import { MarkersContext } from "../../../context/MarkersContext";
import { isMobile } from "react-device-detect";
import { DrawerContext } from "../../../context/DrawerContext";
export const PoisTimeline = () => {
	const { selectedRoute,mapController} = useContext(MainRouteContext);
	const {drawers}=useContext(DrawerContext)
	const { poiList } = useContext(MarkersContext);
	const handlers = {
		clickTimeLinePoi: (aPoi) => {
			const {lat,lon}=aPoi;
			console.log({aPoi});
			poiList.set([{ poi_lat: lat, poi_lon: lon }]);
			var latlng = {
				lat,
				lng:lon,
			};
			mapController.val.flyTo(latlng, 13);
			
			if(isMobile){
				drawers.left.handleDrawerClose();
			}
		},
	};
	return (
		<Timeline align="left">
			{" "}
			{selectedRoute?.val?.intermediate?.map?.((aPoi, i) => {
				return (
					<div onClick={() =>{ handlers.clickTimeLinePoi(aPoi)}}>
						<TimelineItem>
							<TimelineOppositeContent style={{ flex: 0.1 }} />
							<TimelineSeparator>
								<TimelineDot color="secondary" />
								{/* To hide the last conector */}
								{i < selectedRoute?.val?.intermediate?.length - 1 ? (
									<TimelineConnector />
								) : null}
							</TimelineSeparator>
							<TimelineContent style={{ color: "white" }}>
								{aPoi.name}
							</TimelineContent>
						</TimelineItem>
					</div>
				);
			})}
		</Timeline>
	);
};
