import { Divider, List, ListItem, MenuItem, Select } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { PoiAPI } from "../../../api/poi.api";
import { MainRouteContext } from "../../../context/MainRouteContext";
import { MarkersContext } from "../../../context/MarkersContext";
import { useFetchFunction } from "../../../hooks/useFetchFunction";
import CruzeroCard from "../../../components/CruzeroCard";
import { DrawerContext } from "../../../context/DrawerContext";
import { isMobile } from "react-device-detect";
export const SelectPoiType = () => {
	const { mapController } = useContext(MainRouteContext);
	const { drawers } = useContext(DrawerContext);
	const types = useFetchFunction(PoiAPI.allTypes); //The types of poi for the select
	const [poiList, setPoiList] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(1);
	const { poiList: poiListContext } = useContext(MarkersContext);
	const fetchPoiList = async (poiCategoryId) => {
		const res = await PoiAPI.allFromType(poiCategoryId);
		// console.log(res);
		setPoiList(res.data);
		console.log(poiList);
	};
	const handlers = {
		changeSelect: (e) => {
			setSelectedCategory(e.target.value);
			fetchPoiList(e.target.value);
		},
		clickPoiListItem: (poi) => {
			poiListContext.set([poi]);
			var latlng = {
				lat: poi.poi_lat,
				lng: poi.poi_lon,
			};
			if (isMobile){
			 drawers.left.handleDrawerClose();}
			mapController.val.flyTo(latlng, 18);
		},
	};
	return (
		<div>
			<CruzeroCard title="Seleccione Categoria de Punto de Interes">
				<Select
					labelId="simple-select-label"
					id="Select-categories"
					value={selectedCategory}
					onChange={handlers.changeSelect}
				>
					{types?.res?.data?.map((type) => (
						<MenuItem value={type.id}>{type.type_name}</MenuItem>
					))}
				</Select>
				<List>
					{poiList.map((poi) => (
						<>
							<ListItem
								onClick={() => handlers.clickPoiListItem(poi)}
								button
							>
								{poi.poi_name}
							</ListItem>
							<Divider />
						</>
					))}
				</List>
			</CruzeroCard>
		</div>
	);
};
