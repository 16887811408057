import L from "leaflet";
export const createDivIcon=({html,size=25})=>{
     return new L.DivIcon({
        
      
        iconAnchor: [12, 41],
        popupAnchor: [12, -41],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: new L.Point(size, size),
        html,
         className: ''
    });
}
