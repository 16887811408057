import * as React from 'react';
import { useLoadingWidget } from '../../hooks/useLoadingSpinner';
import { Backdrop, CircularProgress } from '@material-ui/core';

export default function LoadingSpinner() {
  const {loading}=useLoadingWidget();
  const handleClose = () => {
    loading.stop();
  };
  

  return (
    
     
      <Backdrop
      style={{zIndex:500}}
        sx={{ color: '#004017'}}
        open={loading.isShown}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
   
  );
}