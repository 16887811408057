import { List, Card, CardContent, Typography } from "@material-ui/core/";

import { Map } from 'react-leaflet';
import CruzeroCard from "../../CruzeroCard";
const ParadasList = () => {
  return (
    <div style={{padding:"14px"}}>
      <CruzeroCard title="Paradas Cercanas">
        <List component="nav" aria-label="main mailbox folders">
          {[1, 2, 3].map(() => {
            return (
              <Card style={{marginTop:"4px"}}>
                <CardContent>
                  <Typography style={{ fontWeight: 600 }} >Linea 29</Typography>
                  <Typography variant="caption" color="textSecondary">
                    200 metros de distancia.
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </List>
      </CruzeroCard>
    </div>
  );
};

export default ParadasList;
