import React from "react";
import TransportAPI from "../../api/transport.api";
import MapView from "../../components/MapView/MapView";
import { MainRouteContextProvider } from "../../context/MainRouteContext";
import { MarkersContextProvider } from "../../context/MarkersContext";
import { useFetchFunction } from "../../hooks/useFetchFunction";
import { TransportSelection } from "./containers/TransportSelection";


/**
 * This component will contain the routes of every transport, obviously selecting the transport type before
 * @returns
 */
export const StaticRoutes = () => {


	return (
		
		<MainRouteContextProvider >
			<MapView activeMarkers={{poi:true}} sidebarChildren={<TransportSelection initialIndex={0} />} LeftSideBarProps={{width:410}} ></MapView>
		</MainRouteContextProvider>
	);
};
