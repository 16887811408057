// Points of interest api
import { defaultHeaders, genericDestroy, url,genericGet } from "./api.utils";

export const PoiAPI = {
    listAll:async()=>{
		const res= await genericGet('poi/all',{poiLonLat:true})
		console.log(res);
		return res;
	},
	readDetail:async(id)=>{
		try {
			const res = await fetch(`${url}poi/detail/${id}`, {
				method: "GET",
				headers: defaultHeaders
			});
			const data = await res.json();
			return data;
		} catch (error) {
			console.log(error);
		}
	},
	sendForm: async (body) => {
		try {
			const res = await fetch(`${url}poi/create`, {
				method: "POST",
				headers: defaultHeaders,
				body: JSON.stringify(body),
			});
			const data = await res.json();
			return data;
		} catch (error) {
			console.log(error);
		}
	},
	allTypes: async () => {
		try {
			const res = await fetch(`${url}poi_type/all`, {
				method: "GET",
				headers: defaultHeaders,
			});
			const data = await res.json();
			return data;
		} catch (error) {
			console.log(error);
		}
	},
	
	allFromType: async (typeId) => {
		try {
			const res = await fetch(`${url}poi/all/category/${typeId}`, {
				method: "GET",
				headers: defaultHeaders,
			});
			const data = await res.json();
			return data;
		} catch (error) {
			console.log(error);
		}
	},
	destroy: async (id) => {
		return await genericDestroy("/poi/delete", id);
	},
};
