import { createContext } from "react";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import { useStateNamed } from "../hooks/useStateNamed";

export const LoadingSpinnerContext = createContext({
	isLoading:{ val: [], set: () => {} },
});

export const LoadingSpinnerContextProvider = ({ children }) => {
	const contextState={
        isLoading:useStateNamed(false)
    }
    return (
		<LoadingSpinnerContext.Provider value={contextState}>
			<LoadingSpinner/>
			{children}
		</LoadingSpinnerContext.Provider>
	);
};


