import React from "react";

export const WalkIconSVG = ({color='green'}) => {
	return (
		<svg
			data-name="Layer 2"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 91.15 92.08"
			width="60"
			height="60"
		>
			<title>Layer 2</title>
			<path
				className="prefix__cls-1"
				fill={color}
				// {...props}
				d="M55.41 57.92l-3.63-8.16L54 51a1 1 0 00.49.13 1 1 0 00.89-.5l3.12-5.19a1.05 1.05 0 00-.39-1.44l-6.76-4.1v-8.54a5.18 5.18 0 00-3.55-4.9 7.27 7.27 0 10-9.51 0 5.17 5.17 0 00-3.55 4.9v4.08l-8.07 9.79a1 1 0 00.07 1.39l4.15 4.16a1.06 1.06 0 00.73.3 1 1 0 00.75-.36l2.83-3.25a13.28 13.28 0 002 4.16l-2.39 10.45-6.13 9.18a1.05 1.05 0 00.22 1.39l5.19 4.15a1 1 0 00.65.23h.14a1.05 1.05 0 00.7-.42l8.3-11.42a1 1 0 00.16-.31l1.42-4.72L47.29 62l3.05 13.21a1 1 0 001 .8h6.23a1 1 0 00.79-.37 1.07 1.07 0 00.23-.86l-3.12-16.6a1.09 1.09 0 00-.07-.24zM37.85 21a5.19 5.19 0 1110.38 0 5.1 5.1 0 01-1.16 3.23 3.08 3.08 0 01-.91-2.2h-2.08a5.19 5.19 0 001.39 3.52 5.11 5.11 0 01-2.43.64A5.2 5.2 0 0137.85 21zm-6.28 27.52l-2.7-2.7 5.87-7.12v6.14zM42.1 64.11L34.54 74.5l-3.6-2.87 5.7-8.55a1.09 1.09 0 00.15-.35l2.11-9.13 4.89 4.89zm10.07 9.81l-2.93-12.69a1 1 0 00-.27-.5l-8.82-8.82a11.35 11.35 0 01-3.34-8.07V31.36a3.12 3.12 0 013.12-3.12h6.23a3.12 3.12 0 013.11 3.12v7.3l-1.21-.73-2-5.86-2 .65L46.21 39a1 1 0 00.45.56l9.49 5.7-2.07 3.44-10.23-5.63-4-9-1.9.84 4.15 9.34a1.09 1.09 0 00.46.49l6.72 3.67V49a1.07 1.07 0 00.09.43l4.1 9.23 2.86 15.26z"
			/>
		</svg>
	);
};
