import {
	Button,
	FormControl,
	Grid,
	TextField,
	InputLabel,
	Select,
	MenuItem,
} from "@material-ui/core/";
import { SwapVerticalCircleRounded, SwapVertRounded } from "@material-ui/icons";
import AdSense from 'react-adsense';
import { useTheme } from "@material-ui/styles";
import React, { useContext } from "react";
import SwipeableViews from "react-swipeable-views";
import routeAPI from "../../../api/ruta.api";
import TransportAPI from "../../../api/transport.api";
import { TaxiInformation } from "../../../components/DataContainers/TaxiInformation";
import { ZebraList } from "../../../components/DataContainers/ZebraList";
import { MainRouteContext } from "../../../context/MainRouteContext";
import { MarkersContext } from "../../../context/MarkersContext";
import { useFetchFunction } from "../../../hooks/useFetchFunction";
import { useGlobalDialog } from "../../../hooks/useGlobalDialog";
import { useLoadingWidget } from "../../../hooks/useLoadingSpinner";
import { useStateNamed } from "../../../hooks/useStateNamed";
import { useStateObject } from "../../../hooks/useStateObject";
import { handleChange } from "../../../utils";
import { RouteTimeline } from "./RouteTimeline";
import {isMobile} from 'react-device-detect';
import { DrawerContext } from "../../../context/DrawerContext";
const taxiTransport = {
	id: -10,
	transport_type_icon: null,
	transport_type_icon_url:
		"https://res.cloudinary.com/dhs9vk9r6/image/upload/v1621194454/trufi_d7731f002a.png",
	transport_type_name: "TAXI",
	transport_type_name_original: null,
};
function CoordinatesInput({ onClick, title, value }) {
	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item md={3} >
				<label className="textColorLightGreen"> {title}</label>
			</Grid>
			<Grid item>
				<TextField
					
					variant="outlined"
					size="small"
					InputProps={{
						style: {
							padding:"0px",
							margin:"0px",
							borderRadius: "20px",
							backgroundColor: "white",
						},
					}}
					value={value}
					onClick={onClick}
				></TextField>
			</Grid>
		</Grid>
	);
}

const RouteCalculation = () => {
	const {
		selectedRoute,
		selectedRouteGeom,
		routesList,
		wayPoints,
		contextFunctions: mainRouteContextFunctions,
		routeCalculationType,
	} = useContext(MainRouteContext);
	const {drawers}=useContext(DrawerContext);
	const transportTypes = useFetchFunction(TransportAPI.getTypes);
	const { poiList } = useContext(MarkersContext);
	const { loading } = useLoadingWidget();
	const dialog=useGlobalDialog();
	const state = {
		indexSwipeableView: useStateObject(0),
		transportType: useStateNamed({
			id: 0,
			transport_type_icon: null,
			transport_type_icon_url: "",
			transport_type_name: "NOTHING",
			transport_type_name_original: null,
		}),
	};
	const theme = useTheme();
	const routeCalculationPoints = {
		origin: wayPoints.origin,
		destination: wayPoints.destination,
		haveValues: () => {
			return (
				routeCalculationPoints.origin.val.lat != 0 &&
				routeCalculationPoints.destination.val.long != 0
			);
		},
		hasOrigin: () => routeCalculationPoints.origin.val.lat != 0,
		hasDestination: () => routeCalculationPoints.destination.val.lat != 0,
	};
	
	const handlers = {
		clickListItem:(data)=>{
			selectedRoute.set(data);
			if (isMobile){
				drawers.left.handleDrawerClose();
			}
			
		},
		clickSwap:()=>{

			const originCopy=wayPoints.origin.val
			const destinationCopy= wayPoints.destination.val
			mainRouteContextFunctions.restart();
			wayPoints.origin.set(destinationCopy);
			wayPoints.destination.set(originCopy);
		},
		clickClean: () => {
			mainRouteContextFunctions.restart();
			poiList.restart();
		},
		clickTransportType: () => {},
		clickOriginInput: () => {
			wayPoints.isSelectingOrigin.set(true);
			wayPoints.isSelectingDestination.set(false);
		},
		clickDestinationInput: () => {
			wayPoints.isSelectingDestination.set(true);
			wayPoints.isSelectingOrigin.set(false);
		},
		clickCalculate: async () => {
			if (routeCalculationPoints.haveValues() && routeCalculationType.val.id!=undefined) {
				selectedRouteGeom.restart();
				loading.begin();
				  
				const { origin, destination } = routeCalculationPoints;
				if (routeCalculationType.val.id == -10) { //Taxi
					await calculateTaxiRoute(
						origin.val.lat,
						origin.val.long,
						destination.val.lat,
						destination.val.long
					);
					if(isMobile){
						drawers.left.handleDrawerClose();
					}
				} else {
					await calculateTransportRoute(
						origin.val.lat,
						origin.val.long,
						destination.val.lat,
						destination.val.long,
						2
					);
				}

				loading.stop();
				dialog.hide();
			}else{
				dialog.showWidget(<h3>Por favor Verifique que ha rellenado todos los datos necesarios</h3>)
			}
		},
	};

	const calculateTaxiRoute = async (from_lat, from_lng, to_lat, to_lng) => {
		const response = await routeAPI.getTaxiCalculationFromMapbox(
			from_lat,
			from_lng,
			to_lat,
			to_lng
		);
		selectedRoute.set(response);
		//swapping for showing in polyline
		const selectedRouteGeomSwap =
			response?.routes?.[0]?.geometry?.coordinates?.map((item) => [
				item[1],
				item[0],
			]);
		selectedRouteGeom.set(selectedRouteGeomSwap);

		//console.log({msg:'taxi calculation',res:response,selectedRouteGeomSwap});
	};
	const calculateTransportRoute = async (
		from_lat,
		from_lng,
		to_lat,
		to_lng,
		city
	) => {
		const response = await routeAPI.getRouteCalculation(
			from_lat,
			from_lng,
			to_lat,
			to_lng,
			city
		);
		const routesRes = response;

		if (routesRes && routesRes.length > 0) {
			console.log(routesRes);
			//La routesData es un array de JSON, por eso al obtener la posicion 0, estoy obteniendo la primer ruta
			routesList.set(routesRes);
			selectedRoute.set(routesRes?.[0]);
			console.log(selectedRoute.val);
			// setRutas((rutas) => ({ ...rutas, routes: [...routesData] }));
		} else {
			routesList.set([]);
			selectedRoute.set({});
		}
	};
	return (
		<div style={{ padding: "14px" }}>
			<h4 htmlFor="" style={{ color: theme.palette.primary.main }}>
				Calculo de rutas
			</h4>
			{/* First Swip */}
			<div style={{ overflow: "hidden" }}>
				<Grid container alignItems="center">
					<Grid item md={10}>
						{" "}
						<CoordinatesInput
							title={"Origen"}
							onClick={handlers.clickOriginInput}
							value={`${wayPoints.origin.val.lat.toFixed(
								5
							)},${wayPoints.origin.val.long.toFixed(5)}`}
						></CoordinatesInput>
						<CoordinatesInput
							title={"Destino"}
							onClick={handlers.clickDestinationInput}
							value={`${wayPoints.destination.val.lat.toFixed(
								5
							)},${wayPoints.destination.val.long.toFixed(5)}`}
						></CoordinatesInput>
					</Grid>
					<Grid item md={2}>
						<Button variant="contained" onClick={handlers.clickSwap}
							color="primary"><SwapVertRounded/></Button>
					</Grid>
					<Grid></Grid>
				</Grid>

				<FormControl fullWidth>
					<h4 htmlFor="" style={{ color: theme.palette.primary.main }}>
						Seleccione Transporte
					</h4>
					<Select
						margin="dense"
						style={{ marginBottom: "10px" }}
						variant="outlined"
						SelectDisplayProps={{
							style: {
								backgroundColor: theme.palette.primary.main,
								color: "white",
							},
						}}
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={routeCalculationType.val}
						onChange={(e) => {
							handleChange(e, routeCalculationType.set);
							selectedRoute.restart();
							selectedRouteGeom.restart();
							routesList.restart();
							//mainRouteContextFunctions.restart();
						}}
					>
						{transportTypes?.res?.data?.map((aTransportType, index) => {
							// console.log('RowForm',index);
							if (aTransportType.transport_type_name == "MICRO")
								return (
									<MenuItem value={aTransportType}>
										{aTransportType.transport_type_name}
									</MenuItem>
								);
						})}
						<MenuItem value={taxiTransport}>{"TAXI"}</MenuItem>
					</Select>
				</FormControl>

				<Grid
					container
					spacing={2}
					style={{ marginBottom: 5 }}
					container
					justify="center"
				>
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							onClick={handlers.clickCalculate}
						>
							Calcular
						</Button>
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							onClick={handlers.clickClean}
						>
							Limpiar
						</Button>
					</Grid>
				</Grid>

				{routeCalculationType.val.transport_type_name == "TAXI" &&
				selectedRoute.val?.routes?.[0]?.duration != undefined ? (
					<TaxiInformation
						textColor={theme.palette.primary.main}
						duration={selectedRoute.val?.routes?.[0]?.duration}
						distance={selectedRoute.val?.routes?.[0]?.distance}
						priceOne={12}
						priceMany={23}
					/>
				) : null}
				<ZebraList
					showScroll
					ListProps={{ style: { backgroundColor: "black" } }}
					dataArray={routesList?.val}
					handlers={{
						clickListItem: (aRoute) => {
							handlers.clickListItem(aRoute);
						},
					}}
				>
					{(aRoute) => {
						return <RouteTimeline routeSteps={aRoute} />;
					}}
				</ZebraList>
				{/* <Grid container>
							<Grid item md={12}>
								<label className="textColorLightGreen">
									{" "}
									Transportes Disponibles
								</label>
							</Grid>

							<TransportTypesRow
								onClick={handlers.clickTransportType}
								values={state.transportTypeList}
							/>
						</Grid> */}
			</div>
			{/* Second Swip */}
			{/* </SwipeableViews> */}
		</div>
	);
};

export default RouteCalculation;
