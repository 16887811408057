import placeholder from "../assets/banner.png";

const AddBanner = () => {

    const bannerSize = 40;

    return(
        <div style={{width:`${bannerSize}%`,
        position:"absolute",
        bottom:0,zIndex:1000,left:`${(100-bannerSize)/2}%`}}>
            <img style={{width:"100%"}} src={placeholder} alt=""/>
        </div>

    );

};

export default AddBanner;
