import { Paper, Grid } from "@material-ui/core";
import React from "react";

export const TermsAndConditions = () => {
	return (
		<Grid container  justify="center" className="bg-color-DarkGreen">
			<Grid item xs={11} md={11}>
				{" "}
				<Paper style={{marginBlock:'1rem'}}  elevation={4} >
					<Grid container  justify="center">
					<Grid item xs={10} md={10} sm={10}>

                    
					<br />
					<h2>POLITICAS DE PRIVACIDAD (Actualizado diciembre 8, 2021)</h2>
					<br />
					<h3>Aportes</h3>
					<p>
						Cruzero, mediante las herramientas de georreferenciación
						provistas por la amplia diversidad de dispositivos móviles
						permite que los usuarios ofrezcan información que ayudará a la
						mejora de la experiencia y operación de la aplicación, así
						como de la plataforma web
					</p>
					<p>
						Al conectarse a Cruzero y usar las herramientas que permiten
						ofrecer información para la mejora de los servicios de
						información de los diversos modos de transporte público,
						puntos de interés o paradas usted acepta haber leído y
						entendido los términos y condiciones aquí presentados.
					</p>
					<p>
						Para ofrecer información a Cruzero, usted NO necesita crearse
						una cuenta en la aplicación, sin embargo, la plataforma
						Cruzero permite al usuario crearse una cuenta en la aplicación
						en la cual se requiere, nombre, edad y ocupación.
					</p>
					<p>
						Estos datos son usados por Cruzero de manera anónima sin
						referenciar específicamente al usuario para poder elaborar
						reportes de uso en la aplicación y que sirven como datos de
						planificación para estudios y análisis que ayudan a la mejora
						de la optimización de los sistemas de transporte público en
						cualquier ciudad del mundo.
					</p>
					<p>
						Cruzero mediante su herramienta de aporte de rutas accede a la
						ubicación en segundo plano del usuario solo y únicamente desde
						el momento en que se inicia a grabar una ruta, esto para
						evitar cortes en los segmentos de ruta y obtener un aporte
						confiable y fidedigno para poder generar un archivo de
						actualización de las rutas y de este modo ayudar a la
						experiencia de los demás usuarios, esto es imprescindible para
						tal efecto y solo para el fin descrito.
					</p>
					<p>
						Un administrador de Cruzero se encarga de verificar la validez
						de cada aporte y actualizar la base de datos cada cierto
						tiempo, siendo esta persona la única con acceso a esta
						información previa al ingreso en la base de datos.
					</p>
					<p>
						Valga la aclaración que el único dato identificativo del
						aporte hacia el usuario es su nombre, su edad y su ocupación,
						mismos que el usuario escoge proveer o no. El uso de Cruzero
						no obliga de ninguna manera o impide su buen funcionamiento si
						el usuario decide no permitir acceder a la ubicación en
						segundo plano.
					</p>
					<p>
						El grabador de rutas nos permite ampliar la base de datos de
						rutas de transporte público para poder ofrecer el servicio de
						información en más regiones, compartirlo con más usuarios y
						así mejorar la experiencia del usuario con la app y la web,
						esta herramienta puede considerarse el pilar fundamental de la
						conexión entre usuario y herramienta, ya que, sin esta, la
						aplicación carece de posibilidad de actualizar rutas de
						transporte público.
					</p>
					<p>
						Al mismo tiempo esto significa que mientras que el usuario no
						detenga el grabador de rutas, Cruzero sigue grabando
						georeferenciadamente,{" "}
						<b>
							<u>de forma anónima</u>{" "}
						</b>
						los movimientos del dispositivo móvil.
					</p>
					<p>
						El usuario entiende y acepta que puede usar otras aplicaciones
						mientras Cruzero está grabando la ruta de transporte público.
					</p>
					<p>
						El grabador de rutas puede ser detenido en cualquier momento
						por el usuario. Una vez detenido el grabador de rutas,
						inmediatamente el acceso a ubicación en segundo plano deja de
						funcionar.
					</p>
					<h3>Elegibilidad para hacer Aportes </h3>
					<p>
						Todo usuario independiente de su rango de edad (a no ser que
						esto vaya en contravención a alguna ley o regulación local)
						puede ofrecer información a Cruzero, de forma anónima a no ser
						que el usuario elija incluir su información registrándose en
						la aplicación.
					</p>
					<p>
						Es de responsabilidad del usuario obtener cualquier
						consentimiento requerido bajo leyes aplicables al ofrecer
						informacion a Cruzero, incluyendo información personal.
					</p>
					<h3>Licencia y restricciones de uso de Aportes</h3>
					<p>
						Toda información ofrecida a Cruzero se convierte en propiedad
						intelectual de Cruzero, el uso de las herramientas de aporte
						no otorga privilegio alguno al usuario.
					</p>
					<p>
						No se autoriza a nadie, reproducir, adaptar, modificar,
						copiar, descompilar, desarmar o mediante ingeniería inversa
						ninguna porción del contenido disponible a través de las
						herramientas de aportes.
					</p>
					<br></br>
					<h2>TÉRMINOS Y CONDICIONES (Actualizado diciembre 8, 2021)</h2>
					El uso de este software implica que acepta y queda obligado por
					los siguientes términos y condiciones:
					<h3>Sólo para uso no comercial</h3>
					Cruzero se pone a su disposición solamente para uso personal y no
					comercial. Si quiere hacer uso comercial de Cruzero, incluyendo,
					pero no limitado a la venta o distribución de Cruzero recibiendo
					un pago, deberá firmar previamente un contrato con Jorge Rodolfo
					Espinoza Landivar C.I. 4703527 SC de ahora en adelante llamado
					PROPIETARIO u obtener el consentimiento previo por escrito del
					PROPIETARIO.
					<h3>Acciones prohibidas</h3>
					No debe distribuir Cruzero o cualquier otro servicio o software
					asociado o derivado de él, modificar, copiar, licenciar o crear
					trabajos derivados de Cruzero a no ser que obtenga una
					autorización por escrito del PROPIETARIO con carácter previo. Si
					quisiera hacer algo de lo descrito anteriormente, por favor
					póngase en contacto con nosotros escribiendo a info@cruzero.net.
					<h3>Propiedad intelectual</h3>
					Usted reconoce que Cruzero ostenta todos los derechos, títulos e
					intereses en y sobre Cruzero, las partes del mismo, incluyendo
					sin limitación todos los Derechos de la Propiedad Intelectual.
					"Derechos de la Propiedad Intelectual" significa cualesquiera y
					todos los derechos existentes en cada momento bajo las leyes
					sobre patentes, propiedad intelectual, secretos comerciales,
					marcas, competencia desleal y cualesquiera otros derechos de
					propiedad, así como cualesquiera solicitudes, renovaciones,
					ampliaciones y restablecimientos de los mismos, que puedan ahora
					o en el futuro estar vigentes en el mundo.
					<h3>Limitación de responsabilidad</h3>
					El PROPIETARIO no se hace responsable por errores en la
					información desplegada por Cruzero. Esta aplicación sólo opera
					como intermediaria de la información entre el Sitio web
					www.cruzero.net y el usuario de Cruzero. El PROPIETARIO no se
					hacen responsable frente a cualquier usuario, en ninguna
					circunstancia, por el uso o mal uso de Cruzero. La presente
					limitación de responsabilidad se aplica a cualquier reclamación
					por daños y perjuicios directos, indirectos, incidentales,
					derivados de, especiales, ejemplares o punitivos, tanto si la
					reclamación se basa en una garantía, responsabilidad contractual
					o extracontractual (incluyendo negligencia) o en otro tipo legal.
					Esta limitación de responsabilidad se aplicará tanto con respecto
					a los daños y perjuicios ocasionados por el uso, mal uso o la
					dependencia o confianza en Cruzero, o a la incapacidad para usar
					Cruzero, o a la interrupción, suspensión o cancelación de Cruzero
					(incluyendo los daños y perjuicios sufridos por terceros). Esta
					limitación se aplicará independientemente de no haberse
					conseguido el propósito esencial de cualquier solución o
					rectificación y con el total alcance permitido por la ley.
                    <br />
                    </Grid>
                    </Grid>
				</Paper>
			</Grid>
		</Grid>
	);
};
