// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LinesList_listBackgroundVariant__2QGJn{\nbackground-color: rgba(255, 255, 255, 0.25);\n}", "",{"version":3,"sources":["webpack://src/pages/StaticRoutes/containers/LinesList.module.css"],"names":[],"mappings":"AAAA;AACA,2CAA2C;AAC3C","sourcesContent":[".listBackgroundVariant{\nbackground-color: rgba(255, 255, 255, 0.25);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listBackgroundVariant": "LinesList_listBackgroundVariant__2QGJn"
};
export default ___CSS_LOADER_EXPORT___;
