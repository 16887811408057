import {
	makeStyles,
	Step,
	StepConnector,
	StepLabel,
	Stepper,
	withStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import clsx from "clsx";
import { Check } from "@material-ui/icons";
import { useQontoStepIconStyles } from "./RouteStepperStyles";
import { BusIconSVG } from "../../../components/Icons/svg-icons/BusIconSVG";
import { WalkIconSVG } from "../../../components/Icons/svg-icons/WalkIconSVG";
import { useTheme } from "@material-ui/styles";
import { ConnectorSVG } from "../../../components/Icons/svg-icons/ConnectorSVG";
import { TimelineCustom } from "../../../components/Timeline/TimelineCustom";

/**
 * Returns the correct icon using its type
 * @param {*} param0 
 * @returns 
 */
function TimelineStepIcon({ type }) {
	const theme = useTheme();
	const iconsList = {
		BUS: <BusIconSVG color={theme.palette.primary.main} />,
		WALK: <WalkIconSVG />,
	};
	const classes = useQontoStepIconStyles();
	return iconsList[type];
}
const TitleRouteTransfers = ({ routeSteps }) => {
	const numberOfTransfers = () => {
		let total = 0;
		routeSteps?.data?.forEach((aStep) => {
			if (aStep.type == "BUS") total++;

		
		});
		return total;
	};
	return (
		<label style={{ color: "white" }}>
			{numberOfTransfers() <= 1
				? `Ruta Directa`
				: (numberOfTransfers() -1)+ " Transbordo"}
		</label>
	);
};
export const RouteTimeline = ({ routeSteps }) => {
	const theme = useTheme();

	const renderText = (aRouteStep) => {
		const { type } = aRouteStep;
		const textStyle = { color: theme.palette.primary.main,fontSize:12 };
		switch (type) {
			case "WALK":
				{
					let totalDistance = 0;
					for (const step of aRouteStep.steps) {
						totalDistance += step.distance;
					}
					return (
						<>
							<label style={textStyle}>
								{totalDistance.toFixed(2) + "m."}
							</label>
							<label style={textStyle}>
								{(aRouteStep.duration / 60).toFixed(1) + "min"}
							</label>
						</>
					);
				}
				break;
			case "BUS":
				{
					return (
						<>
							{" "}
							<label style={textStyle}>{aRouteStep.route_agency}</label>
							<label style={textStyle}>
								{(aRouteStep.duration / 60).toFixed(1) + "min."}
							</label>
						</>
					);
				}
				break;
			default:
				break;
		}
	};

	return (
		<div>
			<div
				style={{
					backgroundColor: "green",
					borderRadius: 8,
					paddingInline: 15,
					width: "60%",
				}}
			>
				<label style={{ color: "white" }}>
					{" "}
					{`Tiempo Total: ${(routeSteps.duration / 60).toFixed(1)}  min.`}
				</label>
				<br />
				<TitleRouteTransfers routeSteps={routeSteps} />
			</div>
			<br />
			<TimelineCustom
				connector={<ConnectorSVG color={theme.palette.primary.main} />}
			>
				{routeSteps.data.map((aRouteStep) => (
					<>
						<TimelineStepIcon type={aRouteStep.type} />
						{renderText(aRouteStep)}
					</>
				))}
			</TimelineCustom>
		</div>
	);
};
