
import React, { useEffect } from "react";
import { Polyline,Tooltip } from "react-leaflet";
import { TaxiInformation } from "../DataContainers/TaxiInformation";

export const StaticPolyline = ({
	type,
	selectedRoute,
	selectedRouteGeom,
}) => {
    //const {duration,distance}=selectedRoute?.routes?.[0];
    useEffect(()=>{
        console.log({msg:'the goem',selectedRouteGeom});
        console.log({type});
    },[selectedRouteGeom])
	return (
		<Polyline
			pathOptions={{ color: "#249D39" }}
			positions={selectedRouteGeom}
		>
			{type == "TAXI" ? (
				<Tooltip direction="top" offset={[16, -30]} opacity={1}>
					<TaxiInformation
                        textColor={'black'}
						duration={selectedRoute?.routes?.[0]?.duration}
                        distance={selectedRoute?.routes?.[0]?.distance}
                        priceOne={12}
                        priceMany={23}
					></TaxiInformation>
				</Tooltip>
			) : null}
		</Polyline>
	);
};
