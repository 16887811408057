import { useState } from "react";

/**
 * This is a hook for making more readable big states. with a set function and a val variable
 * Example:
 * const state={myStateVar:useStateNamed({})}
 * ....
 * myStatateVar.set({yes:'yes'})
 * @param {*} initialState 
 * @returns 
 */
export const useStateNamed = (initialState) => {
	const [state, setState] = useState(initialState);
	const baseState=initialState;
	/**
	 * Restart the state to its initial state
	 */
	const restart=()=>{
		setState(baseState);
	}
	return { val: state, set: setState , restart};
};
