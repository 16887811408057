import React from 'react'

export const ConnectorSVG = ({color='#46673D'}) => {
    return (


    <svg width="50" height="50" viewBox="0 0 500 100" xmlns="http://www.w3.org/2000/svg">
    <g>
    <title>Layer 1</title>
    <g stroke="null" id="svg_8" stroke-width="0">
    <ellipse stroke="#000" fill={color} cx="44.5" cy="45.5" id="svg_1" rx="44.5" ry="44.5"/>
    <rect stroke="#000" fill={color}x="44" y="38" width="354" height="19" id="svg_6"/>
    <ellipse stroke="#000" fill={color} cx="397.5" cy="46.5" id="svg_7" rx="46.5" ry="46.5"/>
    </g>
    </g>

    </svg>
    )
}
