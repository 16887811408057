//export const url = 'http://localhost:8000/api/'

import { HOST } from "../enviroment/.env.constants";

export const url =HOST
export const defaultHeaders = {
    Accept: "Application/json",
    "Content-Type": "Application/json"
    // 'x-access-token': localStorage.getItem('token')
}

export const leafletConstants={
    attribution:'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    url:"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    }
/**
 * A generic API for destroying 
 * NOTE:the url must have the id at the end
 * @param {*} urlToDelete No "/" at the end
 * @param {*} id  
 * @returns 
 */
export const genericDestroy = async (urlToDelete,id) => {
    try {
        const res = await fetch(`${url}${urlToDelete}/${id}`, {
            method: "DELETE",
            crossDomain:true,
            headers: defaultHeaders,
        });
        const data = await res.json();
        return data;
    } catch (error) {
        console.log(error);
    }
}
/**
 * A generic API for GET, it contains the default host
 * @param {*} urlToGet No "/" at the end or beginning
 * @param {JSON} query it should be a json 
 * @returns 
 */
 export const genericGet = async (urlToGet,query,useMainUrl=true) => {
    console.log({url});
        let mainURL = new URL(`${url}${urlToGet}`)  ;  
    
    
    
    var params = query
    mainURL.search = new URLSearchParams(params).toString();
    try {
        const res = await fetch(`${mainURL}`, {
            method: "GET",
            crossDomain:true,
            headers: defaultHeaders,
        });
        const data = await res.json();
        return data;
    } catch (error) {
        console.log(error);
    }
}