import L from "leaflet";
export const createIcon=({iconUrl,size=25})=>{
     return new L.Icon({
        
        iconUrl,
        iconRetinaUrl: iconUrl,
        iconAnchor: [12, 41],
        popupAnchor: [12, -41],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: new L.Point(size, size),
        // className: 'leaflet-div-icon'
    });
}
