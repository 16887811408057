/* eslint-disable jsx-a11y/alt-text */
import "./cardImage.css";
import { Grid } from "@material-ui/core";
import { useInView } from "react-intersection-observer";

import IphoneX from '../../iphonex/iphonex'

const buildData = (isOdd, title, description = [], circleImgURL) => {
	return [
		<div style={{ margin: "1rem 3rem" }}>
			<h2 style={{ marginLeft: 20 }}>{title}</h2>
			<ul children={description.map((info, i) => <li key={i} style={{ margin: '1rem auto' }}>{info}</li>)} />
		</div>,
		<div
			className="row"
			style={{
				padding: '0 7%',
				justifyContent: isOdd ? 'flex-end' : 'start',
			}}
		>
			<img className={isOdd ? "icon right" : "icon left"} src={circleImgURL}></img>
			
			<IphoneX size={.7} />
		</div>
	]
}

const CardImage = ({ title, description, isOdd = false, animation = 'invisible',circleImgURL }) => {
	const { ref, inView } = useInView();
	let data = buildData(isOdd, title, description,circleImgURL)

	if (!isOdd) data.reverse()

	return (
		<Grid
			item
			ref={ref}
			sm={12} xs={12}
			style={{ backgroundColor: !isOdd ? '#CCCCCC' : '#C1C1C1' }}
			className={inView ? `${animation} rowCard` : 'invisible rowCard'}
		>

			<div className="row">
				{data.map(info => (info))}
			</div>
		</Grid>
	);
};

export default CardImage;
