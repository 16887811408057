import { useState } from "react";

/**
 * This hook is vitamined version of useState,
 * in case the state is only one variable contained in an object(JSON)
 * it let the user modify one atribute of the json without making a big appending
 */
export const useStateObject = (initialState) => {
	const [state, setState] = useState(initialState);
	/**
	 * Modifies only the atributes given of a state composed of many atributes
	 * @param {*} AtributesToModify Its a JSON
	 */
	const editState = (AtributesToModify) => {
		setState((prevState) => {
			const newState = {
				...prevState,
				...AtributesToModify,
			};
			return newState;
		});
	};
	return [state, setState, editState];
};
