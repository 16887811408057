import React from "react";
import background from "../../assets/images/about_background.png";
import { Grid } from "@material-ui/core";
export const CityDrawCard = ({ title, description }) => {
	return (
		<div style={{ backgroundImage: `url('${background}')` ,backgroundSize:'cover' ,height:'600px',backgroundPosition:'center', textAlign:'center'}} >
            <Grid container justify="center">
            <Grid item sm={10} className="bg-color-DarkGreen-less-opacity" style={{margin:'2rem',borderRadius:12}}>
            <h4 className="textColorLightGreen">{title}</h4>
			<h4 className="textColorWhite">{description}</h4>
            </Grid>
            </Grid>
		</div>
	);
};
