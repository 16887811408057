import { createContext } from "react";
import { useStateNamed } from "../hooks/useStateNamed";
//This context is for the markers shown in the map
//---Context Creation---

const MarkersContext = createContext({
	stopSearcherShow: { val: [], set: () => {} }, 
	poiList: { val: [], set: () => {},restart:()=>{} }, 
	stopList:{ val: [], set: () => {} ,restart:()=>{}}, 
	contextFunctions: { restart: () => {} }
});

const MarkersContextProvider = (props) => {
	//---Context Data---
	
	const contextState = {
		stopList:useStateNamed([]),
		poiList:useStateNamed([]),
		stopSearcherShow:useStateNamed(true),
		contextFunctions: {
			restart: () => {
				contextState.stopList.restart()
				contextState.poiList.restart()
				contextState.stopSearcherShow.restart()
				
			},
		},
	};

	//Context Provider with the data
	return (
		<MarkersContext.Provider value={contextState}>
			{props.children}
		</MarkersContext.Provider>
	);
};

export { MarkersContext , MarkersContextProvider  };
