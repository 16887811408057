import { List, ListItem } from "@material-ui/core";
import React from "react";
import stylesCSS from "./ZebraList.module.css";
/**
 * This component is a list that alternates colors with an opacity
 * the children has the current iteration item with return render props  pattern
 * @param {*} param0
 * @returns
 */
export const ZebraList = ({
	ListProps,
	dataArray,
	handlers = { clickListItem: (currentItem) => {} },
	children,
	showScroll=false
}) => {
	return dataArray?.length != 0 ? (
		<List {...ListProps}>
			{dataArray?.map((currentItem, index) => {
				return (
					<ListItem 
					style={{overflowX:showScroll?"auto":"hidden"}}
						className={getCurrentClassName(index)}
						props
						button
						onClick={() => handlers.clickListItem(currentItem)}
					>
						{children(currentItem)}
					</ListItem>
				);
			})}
		</List>
	) : null;
};
function getCurrentClassName(index) {
	return index % 2 == 1 ? stylesCSS["list-background-less-opacity"] : "";
}
