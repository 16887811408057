import { Snackbar,Slide } from "@material-ui/core";
import React from "react";

export const WayPointMarker = ({showBool,icon,onClick,message}) => {
	function TransitionDown(props) {
		return <Slide {...props} direction="down" />;
	  }
	return (
		<div>
			{message!=undefined?<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={showBool}
              //  TransitionComponent={TransitionDown}
				message={message}
				key={"top+center"}
			/>:null}
			{showBool ? (
				<img
					src={icon}
					class="map-marker-centered bounce"
					onClick={onClick}
				/>
			) : null}
		</div>
	);
};
