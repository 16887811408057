import MapView from "../../components/MapView/MapView";
import { MainRouteContextProvider } from "../../context/MainRouteContext";
import RouteCalculation from "./containers/RouteCalculation";

const PersonalizedRoutes = () => {
	return (
		<MainRouteContextProvider>
			<MapView
				sidebarChildren={<RouteCalculation />}
				LeftSideBarProps={{ width:410}}
				
			>
				{/*<RouteDetail/>*/}
			</MapView>
		</MainRouteContextProvider>
	);
};

export default PersonalizedRoutes;
